.protected {
    text-align: center;
    display: flex;
    align-items: center;
    @extend %PaddingSections;
    height: calc(100vh - 100px - 80px);
  
    &__wrapper {
      max-width: 522px;
      @include media('tablet') {
        padding: 48px 0;
      }
    }

    input[type='text'],
    input[type='password'] {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        padding-right: 94px;
        padding-left: 24px;
        border: none;
        border-radius: 100px;
        margin: 16px 0;
        border: 1px solid $color-electric-blue-300;
    }

    input[type='submit'] {
        background-color: $color-electric-blue;
        border-color: $color-electric-blue;
        color: $color-white;
        @extend %ButtonBase;
        i::before {
        background-color: $color-white;
        }
        &:hover {
        background-color: $color-electric-blue-300;
        border-color: $color-electric-blue-300;
        }
        &:active {
        background-color: $color-electric-blue-500;
        border-color: $color-electric-blue-500;
        }
        &:disabled {
        background-color: $color-electric-blue-100;
        border-color: $color-electric-blue-100;
        }
    }
  }