.card-1 {
  padding: 32px;
  background-color: $color-white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @extend %BoxShadow;
  border-radius: $br-s;
  @include media('max-phone') {
    padding: 24px 16px;
  }
  @include media('laptop') {
    padding: 37.5px 32px;
  }
  &__up {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    @include media("tablet-s"){
      @include flexbox(row, flex-start, space-between)
    }
  }

  &__down {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    width: 100%;
    > a {
      width: auto !important;
      font-size: $fsize-small !important;
    }
    @include media('laptop') {
      // width: calc( 100% - 101px );
    }
  }

  &__title {
    margin-bottom: 16px;
    overflow-wrap: break-word;
    word-break: keep-all;
    white-space: normal;
    width: 100%;
  }

  &__content {
    margin-bottom: 18px;
    @include media("tablet-s"){
      width: calc( 100% - 101px );
    }
  }

  &__icon {
    display: block;
    width: 95px;
    height: 95px;
    margin-top: 4px;
    margin-left: 6px;
  }
}