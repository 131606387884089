.contact-page {
  background-color: $color-electric-blue-50;
  @extend %PaddingSections;

  &__wrapper {
    padding-left: 24px;
    padding-right: 24px;

    @include media('laptop') {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &__left {
    padding: 20px 10px 0;
    margin-bottom: 67px;
    @include media('laptop') {
      margin-bottom: 0;
      width: calc( 100% - (500px + 77px));
      display: flex;
      align-items: flex-start;
    }
  }

  &__right {
    position: relative;
    display: flex;
    align-items: center;
    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: -33px;
      right: 9px;
      display: block;
      width: 81.11px;
      height: 132.5px;
      background-image: url('../../static/pattern-line.svg');
      background-size: cover;

      @include media('laptop') {
        top: unset;
        bottom: 17px;
        right: -79px;
      }
    }

    @include media('laptop') {
      width: 500px;
    }
  }
  
  &__title {
    margin-bottom: 12px;
  }

  &__subtext {
    margin-bottom: 32px;
    * {
      font-family: $ffamily-general;
      font-size: $fsize-heading-4-dt;
      font-weight: $fweight-bold;
    }
  }

  &__content {
    font-size: $fsize-small;

    @include media('laptop') {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: -74px;
        right: -52px;
        display: block;
        width: 104px;
        height: 104px;
        background-image: url('../../static/curved-arrow.svg');
        background-size: cover;
      }
    }

    ul {
      list-style: none;
      padding-left: 0;

      li{
        position: relative;
        padding-left: 32px;
        line-height: 21px;
        &:not(:last-child){
          margin-bottom: 16px;
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 24px;
          height: 24px;
          background-image: url('../../static/check-circle.svg');
          transform: translateY(-50%);
        }
      }
    }
  }

  &__form-title {
    margin-bottom: 0;
  }

  &__form {
    position: relative;
    box-sizing: border-box;
    padding: 50px 24px;
    border: 1px solid $color-mirage-300;
    background-color: $color-white;
    border-radius: $br-xm;
    position: relative;
    z-index: 2;
    width: 100%;

    @include media('laptop') {
      padding: 50px 40px;
    }
  }

  &__iframe {
    width: 100%;
  }
}