.steps {
  position: relative;
  @extend %PaddingSections;
  
  .wrapper-large {
    padding-right: 24px;
    padding-left: 24px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background-color: $color-blue-navy;
  }

  &__title {
    text-align: center;
    margin-bottom: 48px;
    max-width: 577px;
    margin-left: auto;
    margin-right: auto;
  }

  &__cards {
    @include media('tablet-s') {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      margin-right: -21.5px;
      margin-left: -21.5px;
    }
  }
  
}

.step-card {
  margin-bottom: 48px;
  &:last-of-type {
    margin-bottom: 0;
  }

  @include media('tablet-s'){
    width: calc( (100% - (21.5px * 4)) / 2 );
    margin-right: 21.5px;
    margin-bottom: 0;
    margin-left: 21.5px;
  }

  @include media('laptop'){
    width: calc( (100% - (21.5px * 8)) / 4 );
  }

  &__number {
    margin-bottom: 0;
    color: $color-radiant-yellow;
  }

  &__content {
    margin-bottom: 14px;
    font-family: $ffamily-general;
    font-size: $fsize-heading-4-dt;
    font-weight: $fweight-heading-bold;

    @include media('tablet-s') {
      height: 57.6px;
    }

    @include media('laptop') {
      height: 87px;
    }
  }

  &__picture{
    display: flex;
    justify-content: center;
    height: 100%;
    max-height: 340px;
    object-fit: contain;
    @include media('laptop'){
      height: auto;
    }
  }

  &__image{
    border-radius: 20px;
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1/1;
  }
}