.header-nav__ml {
  position: absolute;
  bottom: 98px;
  margin: 0 22px;
  width: calc( 100% - (22px * 2));

  @include media('laptop') {
    position: relative;
    bottom: unset;
    width: auto;
  }
}

.wpml-selector {
  position: relative;
  height: 48px;
  padding: 0;
  margin: 0;
  list-style: none;

  a {
    text-decoration: none;
    color: $color-blue-navy;
    @extend %GeneralFontRegular;
  }

  &__selected {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    border: 1px solid $color-mirage-200;
    background-color: $color-mirage-100;
    border-radius: 24px;
    transition: all .2s ease-in-out;
    @include media('laptop') {
      padding: 0 20px 0 24px;
    }

    span {
      display: block;
      width: calc( 100% - 48px);
      line-height: 1;
      text-align: center;
      @include media('laptop') {
        display: none;
      }
    }

    i {
      font-size: 24px;
      transition: all .2s ease-in-out;
      @include media('laptop') {
        margin-left: 8px;
      }
    }

    &[data-active="true"] {
      border-radius: 0 0 24px 24px;
      @include media('laptop') {
        border-radius: 24px 24px 0 0;
      }

      i {
        transform: rotate(180deg);
      }
    }
  }

  &__submenu {
    position: absolute;
    bottom: 100%;
    left: 0;
    overflow: hidden;
    width: 100%;
    padding: 0;
    border: 1px solid $color-mirage-200;
    border-bottom: none;
    margin: 0;
    background-color: $color-mirage-100;
    list-style: none;
    border-radius: 24px 24px 0 0;
    transition: all .2s ease-in-out;
    opacity: 0;
    transform: translateY(50%);
    visibility: hidden;

    &[data-active="true"] {
      opacity: 1;
      transform: translateY(0%);
      visibility: visible;
    }

    @include media('laptop') {
      bottom: unset;
      top: 100%;
      border-radius: 0 0 24px 24px;
      border: 1px solid $color-mirage-200;
      border-top: none;
      transform: translateY(-50%);
    }

    > li > a {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0 24px;
      span {
        display: block;
        width: calc( 100% - 48px);
        line-height: 1;
        text-align: center;
        @include media('laptop') {
          display: none;
        }
      }
  
      i {
        font-size: 24px;
      }
    }
  }
}