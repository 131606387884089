.faq {
  position: relative;

  /* &::after, 
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 81.11px;
    height: 132.5px;
    background-image: url('../../static/pattern-line.svg');
    background-size: cover;
  } */

  &::before {
    top: -46px;
    left: -24px;
  }

  &:after {
    bottom: -46px;
    right: -24px;
    z-index: -1;
  }

  &__title {
    margin-top: 80px !important;
    margin-bottom: 50px;
    text-align: center;
    font-size: $fsize-heading-1-dt !important;

    @include media('laptop') {
      margin-bottom: 80px;
      text-align: left;
      font-size: $fsize-heading-1-dt !important;
    }
  }

  &__group {
    margin-bottom: 12px;
  }

  &__category {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 16px 44px 16px 24px;
    border: 2px solid $color-mirage-300;
    background-color: $color-mirage-100;
    color: $color-blue-navy;
    font-family: $ffamily-heading;
    font-size: $fsize-heading-4-dt;
    font-weight: $fweight-heading-regular;
    line-height: 24px;
    text-align: left;
    text-decoration: none;
    border-radius: $br-m;
    cursor: pointer;
    transition: all .2s ease-in-out;

    > div {
      margin-top: 2px;
    }

    &:hover {
      background-color: $color-mirage-200;
    }

    &-arrow, &-caret {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    }

    &-arrow {
      color: $color-electric-blue;
      margin-right: 4px;
      i {
        font-size: 23px;
        
      }
    }

    &-caret {
      position: absolute;
      top: 18px;
      right: 20px;
      transition: all .2s ease-in-out;
      i {
        font-size: 24px;
        line-height: 1;
      }
    }

    &[data-active="true"] {
      border-color: $color-electric-blue;
      color: $color-electric-blue;
      .faq__category-caret {
        transform: rotate(180deg);
      }
      .faq__category-arrow i::before {
        background-color: $color-electric-blue;
      }
    }
  }

  &__items {
    height: 0;
    margin-top: 0;
    background-color: $color-mirage-100;
    transform: scaleY(0);
    transition: all .2s ease-in-out;
    visibility: hidden;
    opacity: 0;

    &[data-active="true"] {
      transform: scaleY(1);
      height: auto;
      margin-top: 12px;
      visibility: visible;
      opacity: 1;
    }
  }
}

.faq-item {
  &__caret {
    position: absolute;
    top: 50%;
    right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    transition: all .2s ease-in-out;
    background-image: none !important;
    i {
      font-size: 24px;
      line-height: 1;
    }
  }

  &__title {
    position: relative;
    width: 100%;
    padding: 24px 58px 24px 24px;
    border: none;
    border-bottom: 1px solid $color-mirage-300;
    background-color: $color-mirage-100;
    color: $color-blue-navy;
    font-family: $ffamily-heading;
    font-size: $fsize-heading-4-dt;
    font-weight: $fweight-heading-regular;
    text-align: left;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: $color-mirage-200;
    }

    &[data-active="true"] {
      .faq-item__caret {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__content {
    overflow: hidden;
    height: 0;
    border-bottom: 1px solid $color-mirage-300;
    font-size: $fsize-small;
    transform: scaleY(0);
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-in-out;

    &[data-active="true"] {
      transform: scaleY(1);
      height: auto;
      padding: 29px 14px 10px;
      visibility: visible;
      opacity: 1;
    }
  }

  &__icon {
    line-height: 1;
    display: block;
    width: 32px;
    margin-right: 10px;
    background-image: none !important;

    img {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }
  }

  &--widget {
    margin-bottom: 20px;
    .faq-item__title {
      display: flex;
      align-items: center;
      border: none;
      background-color: $color-white;
      border-radius: $br-s;
      @extend %BoxShadow;

      &[data-active="true"] {
        color: $color-electric-blue;
        .faq-item__caret {
          
        }
      }
    }

    .faq-item__content {
      border: none;
    }
  }
}


.faq-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .faq-sidebar {
    width: 363px;
  }

  .faq-content {
    width: calc( 100% - (363px + 37px));
  }

  .faq__category {
    margin-bottom: 11px;
  }
  .faq__items[data-active="true"] {
    margin-top: 0;
  }
}

.faq-widget {
  @extend %PaddingSections;

  .wrapper-small {
    padding-left: 28px;
    padding-right: 28px;
    @include media('laptop') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 58px;
  }

  &__button {
    text-align: center;
    margin-top: 48px;
  }
}