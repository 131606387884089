%GridContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  
  @include media('tablet') {
    flex-direction: row;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.grid-item {
  width: 100%;
  margin-bottom: 20px;

  @include media('tablet') {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.grid {
  &-3 {
    @extend %GridContainer;

    .grid-item {
      @include media('tablet') {
        width: calc( ( 100% - ( 10px * 4 ) ) / 2 );
      }

      @include media('laptop') {
        width: calc( ( 100% - ( 10px * 6 ) ) / 3 );
      }
    }

    &--over {
      .grid-item {
        &:nth-child(1) {
          margin-top: -156px;
        }
        @include media('tablet') {
          &:nth-child(1),
          &:nth-child(2) {
            margin-top: -156px;
          }
        }
        @include media('laptop') {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            margin-top: -246px;
          }
        }
      }
    }
  }
}
