.gray-cards {
  padding: 0 0px 50px;
  z-index: 2;
  position: relative;

  &__wrapper {
    padding-left: 26px;
    padding-right: 26px;

    @include media('tablet') {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -14px;
      margin-right: -14px;
    }
  }
  .gray-card{
    margin-bottom: 28px;
    &:first-child {
      margin-top: -70px;
      @include media('tablet') {
        margin-top: 0;
      }
    }
    &:last-child{
      margin-bottom: 0px;
    }

    @include media('tablet') {
      margin-left: 14px;
      margin-right: 14px;
      width: calc( (100% - ( 14px * 4) ) / 2);
      

      &:nth-child(1),
      &:nth-child(2) {
        margin-top: -170px;
      }

      &:last-child{
        margin-bottom: 28px;
      }
    }
  }
}

.gray-card {
  border-radius: $br-s;
  padding: 32px 24px;
  border: 1px solid $color-mirage-300;
  background-color: $color-white;
  @extend %BoxShadow;

  @include media('tablet') {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    @include media('tablet') {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  &__image {
    border-radius: 100%;
    width: 137px;
    height: 137px;
    object-fit: cover;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__content {
    color: $color-mirage-600;
    margin-bottom: 12px;
    @include media('tablet') {
      margin-bottom: 12px;
    }
    p{
      margin-top: 0;
      margin-bottom: 12px;
    }
  }
}