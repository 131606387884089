.numbers {
  position: relative;
  text-align: center;
  @extend %PaddingSections;
  &__title {
    margin-bottom: 28px;
    color: $color-blue-navy;
  }

  &--curve {
    background-color: $color-electric-blue-100;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-image: url('../../static/pattern.png');
      background-size: cover;
      opacity: 0;
      @include media("tablet") {
        opacity: 0.3;
      }
    }
  }

  &--lines {
    background-color: $color-electric-blue-100;
    .numbers__list {
      &::after, 
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 81.11px;
        height: 132.5px;
        background-image: url('../../static/pattern-line.svg');
        background-size: cover;
        z-index: -1;
      }

      &::before {
        top: -85px;
        left: -46px;
      }

      &:after {
        bottom: -5px;
        right: -40px;
      }
    }
  }

  &__background {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    @include media("tablet") {
      opacity: 0.3;
    }
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: -24px;
    @include media("laptop") {
      flex-direction: row;
      justify-content: center;
      margin-left: -14px;
      margin-right: -14px;
    }
    &--mobile {
      margin-right: -16px;
      margin-left: -16px;
      .splide {
        min-height: 325px;
      }
      .splide__track{
        min-height: 300px;
      }
      .splide__slide {
        margin-right: 28px;
        max-width: calc(66.6%);
        @include media('max-phone') {
          max-width: calc(86%)
        }
      }
      .splide__pagination {
        margin-top: 20px;
        .splide__pagination__page {
          background-color: #ccd3df !important;
          height: 8px !important;
          width: 8px !important;

          &.is-active {
            background-color: $color-electric-blue !important;
            height: 8px !important;
            width: 8px !important;
          }
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 248px;
    padding: 48.5px 16px;
    border: 1px solid $color-mirage-300;
    margin-right: 0px;
    margin-bottom: 28px;
    margin-left: 0px;
    background-color: $color-mirage-100;
    border-radius: 16px;
    //CHANGELOG Se cambiar el box shadow por diferencia significativa entre el figma y el browser
    box-shadow: 0px 35px 30px -10px rgba(0, 65, 255, 0.08);
    gap: 6px;
    transition: all .2s ease-in-out;
    @include media("laptop") {
      width: calc((100% - (28px * 2)) / 4);
      margin-right: 14px;
      margin-left: 14px;
    }

    &--mobile {
      width: 100%;
    }

    &:hover {
      transform: translateY(-10px);
    }
  }

  &__icon {
    max-height: 48px;
    max-width: 48px;
    img {
      height: 100%;
      width: 100%;
    }
  }

  &__number {
    color: $color-electric-blue;
    font-family: $ffamily-general;
    font-size: $fsize-heading-1-dt;
    font-style: normal;
    font-weight: $fweight-bold;
    line-height: 95%;
    text-align: center;
    display: inline-flex;
    align-items: center;
    @include media('phone') {
      font-size: $fsize-heading-stadistic;
    }
    .odometer-auto-theme, .odometer-theme-default {
      margin-top: 0px;
    }
  }

  &__separator {
    line-height: 1;
    margin-top: -6px;
    margin-left: 4px;
    margin-right: 4px;
  }

  &__number [class*="odometer"] {
    color: $color-electric-blue;
    font-family: $ffamily-general;
    font-size: inherit;
    font-style: normal;
    font-weight: $fweight-bold;
  }

  &__description {
    font-family: $ffamily-general;
    font-size: $fsize-root;
    font-style: normal;
    font-weight: $fweight-regular;
    line-height: 150%;
    text-align: center;
  }

  &__wrapper {
    margin-bottom: -28px;
    position: inherit;
    z-index: 2;
    @include media("laptop") {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
