.form-search {

  @include media('laptop') {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  input {
    font-size: $fsize-small;
    color: $color-mirage-600;
    &:focus {
      outline-color: $color-radiant-yellow;
    }
  }
  &__input-button {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: 60px;
    background-color: $color-white;
    border-radius: 100px;
    @include media('laptop') {
      width: 540px;
    }

    input {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      padding-right: 94px;
      padding-left: 24px;
      border: none;
      border-radius: 100px;
    }

    button {
      display: flex;
      position: absolute;
      right: 0;
      top: 0;
      justify-content: center;
      align-items: center;
      width: 82px;
      height: 60px;
      border: none;
      background-color: $color-radiant-yellow;
      border-radius: 100px;
      cursor: pointer;
      i {
        font-size: 28px;
      }
    }
  }

  &__filters {
    @include media('laptop') {
      display: flex;
      align-items: center;
      width: calc( 100% - (540px + 32px));
    }
  }

  &__filter-title,
  select {
    margin-bottom: 12px;
    margin-right: 23px;
    @include media('laptop') {
      margin-bottom: 0px;
    }
  }

  &__filter-title {
    display: block;
    text-transform: uppercase;
    font-size: $fsize-small;
    @extend %GeneralFontBold;
  }

  select {
    position: relative;
    height: 48px;
    padding: 0 56px 0 24px;
    border: none;
    border-radius: 24px;
    appearance:none;
    background-image: url('../../static/nav-arrow-down.svg');
    background-position-x: calc(100% - 20px);
    background-position-y: 13px;
    background-repeat: no-repeat;
    transition: all .2s ease-in-out;
    &:focus {
      outline: none;
      border-radius: 24px 24px 0 0;
    }
  }

  &--yellow {
    input {
      &:focus {
        outline-color: $color-blue-navy;
      }
    }
    .form-search__input-button {
      button {
        background-color: $color-blue-navy;
        cursor: pointer;
        i {
          font-size: 28px;
          &::before{
            background-color: $color-white;
          }
        }
      }
    }
  }

  &--light-blue {
    input {
      &:focus {
        outline-color: $color-electric-blue;
      }
    }
    .form-search__input-button {
      button {
        background-color: $color-electric-blue;
        cursor: pointer;
        i {
          font-size: 28px;
          &::before{
            background-color: $color-white;
          }
        }
      }
    }
  }
}