.popup-contact {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 30px;
  background-color: $color-white;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;
  
  @include media('tablet') {
    width: 700px;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    color: $color-blue-navy;
    cursor: pointer;
    i {
      font-size: 1.7rem;
    }
  }

  &__overlay {
    position: fixed;
    z-index: 99999;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba($color-blue-navy, 0.7);
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;

    &.active {
      opacity: 1;
      visibility: visible;

      .popup-contact {
        transform: translateX(0%);
        opacity: 1;
        visibility: visible;
      }
    }    
  }
}