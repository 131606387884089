%Wrapper{
  box-sizing: border-box;
  margin: 0 auto !important;
  padding-left: 16px;
  padding-right: 16px;

  @include media('desktop'){
    padding-left: 0px;
    padding-right: 0px;
  }
}

.wrapper {
  &-x-large{
    @extend %Wrapper;
    max-width: $wrapper-xlarge;
  }

  &-large{
    @extend %Wrapper;
    max-width: $wrapper-large;
  }

  &-medium{
    @extend %Wrapper;
    max-width: $wrapper-medium;
  }

  &-xmedium{
    @extend %Wrapper;
    max-width: $wrapper-xmedium;
  }

  &-small{
    @extend %Wrapper;
    max-width: $wrapper-small;
  }

  &-mobile{
    @extend %Wrapper;
    max-width: $wrapper-mobile;
  }
}
