.hero {

  &--partners {
    .hero__title {
      font-size: $fsize-heading-1-dt;
    }

    .hero__text {
      font-size: $fsize-small;
    }

    .hero__image {
      @include media("tablet") {
        width: 460px;
        img {
          width: 240px !important;
          height: 240px !important;
        }
      }
    }

    @include media("tablet") {
      flex-direction: row !important;
      &::before {
        background-image: url("../../static/pattern.png") !important;
        background-position: 10% center;
      }
    }
  }
  &--center.hero--partners {
    padding-top: 50px;
    min-height: 340px;

    @include media("tablet"){
      padding-top: 0px;
    }
  }

  
}

.partners {
  &__container {
    padding-top: 3rem;
    padding-bottom: $vgutter;
    @include flexbox(column, start, start);
    @include media("tablet-l") {
      @include flexbox(row, start, start);
    }

    &.center {
      @include flexbox(column, center, start);
    }
  }
  &__aside {
    width: 100%;
    padding-bottom: 32px;
    @include media("tablet-l") {
      width: 260px;
      margin-right: 60px;
    }
  }
  &__active-filters {
    text-align: center;
    @include media("tablet-s"){
      text-align: left;
    }
    h2 {
      color: $color-blue-navy;
      font-family: $ffamily-heading;
      font-size: $fsize-heading-2-dt;
      font-style: normal;
      font-weight: $fweight-heading-regular;
      line-height: 130%;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  &__content {
    width: 100%;
    @include media("tablet") {
      width: calc( 100% - (260px + 60px));
    }
  }
  &__grid {
    overflow: auto;
    padding: 1rem 1.5rem 0 1.5rem;
    width: 100%;
    @include flexbox(column, center, center);
    @include media("phone-l") {
      flex-wrap: wrap;
      margin-left: -12.5px;
      margin-right: -12.5px;
      @include flexbox(row, center, flex-start);
    }

    @include media("tablet") {
      margin-left: 0;
      margin-right: 0;
    }

    &-item-overlay {
      position: absolute;
      background-color: $color-electric-blue;
      top: 0;
      bottom: 0;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
      transition: all .2s ease-in-out;
      @include flexbox(row, center, center);
    }

    &-item {
      background: $color-white;
      border-radius: $br-m;
      box-shadow: 0px 12px 30px rgba(0, 65, 255, 0.08);
      margin-bottom: 2rem;
      min-height: 205px;
      width: 100%;
      position: relative;
      overflow: hidden;
      @include media("phone-l") {
        margin-left: 12.5px;
        margin-right: 12.5px;
        margin-bottom: 1.5rem;
        width: calc( (100% - (12.5px * 4)) / 2);
      }
      @include media("tablet-s") {
        width: calc( (100% - (12.5px * 6)) / 3);
      }
      padding: 0 0.5rem;
      @include flexbox(column, center, center);
      img {
        max-width: calc( 100% - 48px);
        max-height: 70px;
      }

      &:hover {
        .partners__grid-item-overlay {
          visibility: visible;
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
  &__list {
    &-item {
      text-decoration: none;
      @include flexbox(column, center, center);
      @include media("phone-l") {
        margin-bottom: 2rem;
        @include flexbox(row, center, space-between);
        &:nth-child(even) {
          @include flexbox(row-reverse, center, space-between);
        }
      }
      &__content {
        width: 100%;
        margin-bottom: 32px;
        @include media("phone-l") {
          width: 50%;
          margin-bottom: 0;
        }
        @include media("laptop") {
          width: calc( 100% - (400px + 80px));
        }
      }
      &__title {
        color: $color-blue-navy;
        font-family: $ffamily-heading;
        font-size: $fsize-heading-2-dt;
        font-style: normal;
        font-weight: $fweight-heading-regular;
        line-height: 130%;
      }
      &__excerpt {
        margin-top: 0;
        font-family: $ffamily-general;
        font-style: normal;
        font-weight: $fweight-regular;
        font-size: $fsize-small;
        line-height: 150%;
        color: $color-blue-navy;
        margin-bottom: 20px;
        
      }
      &__logo {
        background: $color-white;
        border-radius: $br-m;
        box-shadow: 0px 12px 30px rgba(0, 65, 255, 0.08);
        margin-bottom: 1.5rem;
        min-height: 205px;
        max-width: 327px;
        @include media("phone-l") {
          width: 40%;
          margin-bottom: 0;
          min-height: 300px;
          max-width: unset;
        }
        @include media("laptop") {
          width: 400px;
        }
        @include flexbox(column, center, center);
        padding: 0 0.5rem;
        img {
          max-width: calc( 100% - 48px);
          max-height: calc( 100% - 48px);

          @include media("laptop") {
            max-width: calc( 100% - 60px);
            max-height: calc( 100% - 60px);
          }
        }
      }
    }
  }

  &__container.center .partners__grid {

  }
  &__paginator {
    padding-top: 2rem;
    @include flexbox(row, center, center);
  }
  &__filter-title {
    color: $color-blue-navy;
    font-family: $ffamily-heading;
    font-size: $fsize-heading-4-dt;
    font-style: normal;
    font-weight: $fweight-heading-regular;
    line-height: 24px;
    text-align: center;
    @include media("tablet") {
      text-align: left;
    }
  }
  &__filter {
    &-list {
      display: inline-block;
      margin-bottom: 15px;
      position: relative;
      width: 100%;
    }
    &-select {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 48px;
      padding: 12px 20px 12px 24px;
      padding: 0 56px 0 24px;
      background: $color-white;
      background-image: url('../../static/nav-arrow-down.svg');
      background-repeat: no-repeat;
      color: $color-blue-navy;
      font-family: $ffamily-general;
      font-size: $fsize-small;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      -webkit-appearance: none;
      -moz-appearance: none;appearance: none;
      cursor: pointer;
      outline: 0;
      border-color: $color-mirage-200;
      border-radius: 24px;
      appearance:none;
      background-position-x: calc(100% - 20px);
      background-position-y: 13px;
      transition: all .2s ease-in-out;
    &:focus {
      outline: none;
      border-radius: 24px 24px 0 0;
    }
      &::-ms-expand {
        display: none;
      }

      &::hover ~ .partners__filter-select-arrow,
      &::focus ~ .partners__filter-select-arrow {
        border-color: #000000;
      }

      &::disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      // &-arrow {
      //   border: 1px solid $color-blue-navy;
      //   border-width: 0 2px 2px 0;
      //   display: inline-block;
      //   height: 13px;
      //   padding: 3px;
      //   position: absolute;
      //   right: 24px;
      //   top: 12px;
      //   transform: rotate(45deg);
      //   -webkit-transform: rotate(45deg);
      //   width: 13px;
      // }
    }
  }
}

.partner-single {
  &__content {
    margin-bottom: 16px;
  }
}
