.hero-resource {
  position: relative;
  margin-top: -78px;
  padding-top: 78px;
  border-radius: 0 0 $br-m $br-m;

  @include media('tablet') {
    margin-top: -101px;
    padding-top: 101px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('../../static/pattern.png');
    background-size: cover;
    opacity: 0;
    @include media('tablet') {
      opacity: 0.1;
    }
  }
  
  &__wrapper {
    padding-left: 24px;
    padding-right: 24px;
    @include media('laptop') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--simple {
    border-radius: 0 $br-s $br-s 0;
    &::after {
      display: none;
    }
    .hero-resource__wrapper {
      padding-bottom: 60px;
      @include media('tablet') {
        padding-bottom: 89px;
      }
    }
  }

  &__container {
    position: relative;
    z-index: 2;
    padding: 33px 0 60px;
    box-sizing: unset;

    @include media('tablet') {
      display: flex;
      align-items: center;
      padding: 40px 0 80px;
      min-height: 400px;
      justify-content: space-between;

      &.press {
        padding: 150px 0 210px;
        min-height: auto;
      }
    }
  }

  &__container-filters {
    position: relative;
    z-index: 2;
    padding: 33px 0;
    @include media('tablet') {
      display: flex;
      flex-direction: column;
      padding: 150px 0 210px;
      min-height: 400px;
      justify-content: center;
    }
  }

  &__columns {
    @include media('tablet') {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__left {
    margin-bottom: 20px;
    @include media('tablet') {
      margin-bottom: 0px;
      width: 540px;
    }
  }

  &__right {
    @include media('tablet') {
      width: calc( 100% - (540px + 32px));
    }
  }

  &__title,
  &__content {
    text-align: left;
  }

  &__title {
    margin: 0 0 20px;
  }

  &__content {
    margin-bottom: 20px;
    &.just-desktop {
      display: none;
      @include media('tablet') {
        display: block;
      }
    }

    &.just-mobile {
      padding-top: 32px;
      @include media('tablet') {
        display: none;
      }
    }

  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media('tablet') {
      justify-content: flex-end;
    }
  }
  &__image {
    width: 240px;
    height: 240px;
    @include media('tablet') {
      width: 400px;
      height: 400px;
    }
  }

  &--diagonal {
    border-radius: 0;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 80px;
      bottom: -1px;
      background-color: $color-white;
      clip-path: polygon(100% 0, 0 100%, 100% 100%);
    }

    .hero-resource__wrapper {
      padding-bottom: 120px;
      @include media('tablet') {
        padding-bottom: 162px;
      }
    }
  }

  &--yellow {
    background-color: $color-radiant-yellow;
  }

  &--light, &--light-blue {
    background-color: $color-electric-blue-100;
    &::before {
      @include media('tablet') {
        opacity: 0.3;
      }
    }
  }

  &--dark {
    background-color: $color-blue-navy;

    .hero-resource__title {
      color: $color-radiant-yellow;
    }
    .hero-resource__content {
      color: $color-white;
    }
  }

  &--light-blue {
    .hero-resource__title {
      color: $color-electric-blue;
    }
  }
}