html {
  .otgs-development-site-front-end {
    display: none !important;
  }
  scroll-padding-top: 98px;
  @include media('laptop'){
    scroll-padding-top: 121px;
  }
}

body {
  padding-top: 78px;
  @include media('laptop') {
    padding-top: 101px;
  }
}

.header {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  border-bottom: 1px solid $color-mirage-200;
  background-color: $color-white;
  color: $color-blue-navy;
  transition: all .2s ease-in-out;
  @extend %BoxShadow;

  @include media('laptop') {
    box-shadow: none;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 77px;
    padding-right: 24px;
    padding-left: 24px;

    @include media('laptop') {
      padding-right: 0;
      padding-left: 0;
      height: 100px;
    }
  }
  &__logo-light {
    display: none;
  }

  &__logo {
    display: inline-flex;
    align-items: center;
  }

  &--dark,
  &--yellow,
  &--light,
  &--light-blue {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  &--light, &--light-blue {
    border-bottom: 1px solid $color-mirage-100 !important;
    @include media('laptop') {
      border-bottom: 1px solid $color-electric-blue-100 !important;
    }
  }

  &--dark {
    @include media('laptop') {
      border: none !important;
    }
    .header__logo-dark {
      display: none !important;
    }
    .header__logo-light {
      display: block !important;
    }
  }

  &--sticky {
    background-color: $color-white !important;
    .header__logo-dark {
      display: block !important;
    }
    .header__logo-light {
      display: none !important;
    }
  }
}
