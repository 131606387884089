.press {
  &__wrapper {
    @include media('tablet') {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &__sidebar {
    @include media('tablet') {
      width: 148px;
      margin-right: 42px;
    }
  }

  &__content {
    @include media('tablet') {
      width: calc( 100% - (148px + 42px));
    }
  }
}