.hero {
  padding: 20px 28px 52px;
  border-radius: $br-l;
  @include media('tablet-s') {
    padding: 60px 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    min-height: 438px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../static/hero-lines.svg');
      background-size: cover;
      opacity: .3;
      z-index: 1;
    }
  }

  @include media('laptop') {
    padding-left: 105px;
    padding-right: 105px;
  }

  * {
    z-index: 3;
  }

  &-section {
    margin-bottom: 40px;
  }

  &__title, &__text {
    margin-bottom: 20px;
  }

  &__text {
    margin-bottom: 28px;
    p {
      font-size: inherit;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    @include media('tablet') {
      max-width: 650px;
      padding-right: 28px;
      font-size: $fsize-heading-4-dt;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    @include media('phone-l') {
      display: block;
    }

    .btn-primary:first-of-type,
    .btn-secondary:first-of-type {
      margin-bottom: 20px;

      @include media('phone-l') {
        margin-right: 20px;
      }

      @include media('tablet') {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--yellow {
    background-color: $color-radiant-yellow;
  }

  &--dark {
    background-color: $color-blue-navy;
    
    .hero__title, .hero__text {
      color: $color-white;
    }
  }

  &--light {
    background-color: $color-electric-blue-100;
  }

  &--top {
    padding-top: 28px;
    padding-bottom: 72px;
    margin-top: 59px;

    .hero__image {
      margin-top: -59px;
      margin-bottom: 58px;
      img{
        width: 259px;
      }
      
      @include media('tablet') {
        margin-top: -118px;
        img{
          width: 432px;
          height: 424px;
        }
      }
    }
    
  }

  &--center {
    margin-top: 40px;
    .hero__image {
      margin-bottom: 49px;
      @include media('tablet'){
        margin-bottom: 0px
      }
      img {
        width: 239px;
        height: 239px;
        @include media('laptop') {
          width: 400px;
          height: 400px;
        }
      }
    }
  }

  &--top, &--center{
    @include media('tablet') {
      padding-top: 0px;
      padding-bottom: 0px;
      .hero__image {
        @include media('tablet') {
          margin-bottom: 0px;
        }
      }
    }
  }
}