%ButtonBase {
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 100px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: $ffamily-heading;
  font-size: $fsize-general;
  font-weight: $fweight-heading-regular;
  justify-content: center;
  line-height: 1;
  min-height: 48px;
  padding: 0px 24px;
  text-decoration: none;
  text-align: center;
  transition: all .2s ease-in;
  @include media('max-phone') {
    padding: 10px 16px;
    line-height: 1.2;
  }
  @include media('laptop') {
    font-size: $fsize-small;
  }
  width: auto;
  i {
    font-size: 1.5rem;
    &:first-child {
      margin-right: 8px;
    }
    &:last-of-type {
      margin-left: 8px;
    }
  }
}

.btn-primary {
  &,
  &:link {
    background-color: $color-electric-blue;
    border-color: $color-electric-blue;
    color: $color-white;
    @extend %ButtonBase;
    i::before {
      background-color: $color-white;
    }
    &:hover {
      background-color: $color-electric-blue-300;
      border-color: $color-electric-blue-300;
    }
    &:active {
      background-color: $color-electric-blue-500;
      border-color: $color-electric-blue-500;
    }
    &:disabled {
      background-color: $color-electric-blue-100;
      border-color: $color-electric-blue-100;
    }
  }
}

.btn-secondary {
  &,
  &:link { 
    border-color: $color-electric-blue;
    color: $color-electric-blue;
    @extend %ButtonBase;
    i::before {
      background-color: $color-electric-blue;
    }
    &:hover {
      border-color: $color-electric-blue-300;
      color: $color-electric-blue-300;
      i::before {
        background-color: $color-electric-blue-300;
      }
    }
    &:active {
      border-color: $color-electric-blue-500;
      color: $color-electric-blue-500;
      i::before {
        background-color: $color-electric-blue-500;
      }
    }
    &:disabled {
      border-color: $color-electric-blue-100;
      color: $color-electric-blue-100;
      i::before {
        background-color: $color-electric-blue-100;
      }
    }
  }
}

.btn-white {
  &,
  &:link {
    background-color: $color-mirage-100;
    border-color: $color-mirage-100;
    color: $color-blue-navy;
    @extend %ButtonBase;
    i::before {
      background-color: $color-blue-navy;
    }
    &:hover {
      background-color: $color-mirage-200;
      border-color: $color-mirage-200;
    }
    &:active {
      background-color: $color-mirage-300;
      border-color: $color-mirage-300;
    }
    &:disabled {
      background-color: $color-mirage-300;
      border-color: $color-electric-blue-100;
    }
  }
}

.btn-text {
  &,
  &:link {
    color: $color-electric-blue;
    @extend %ButtonBase;
    i::before {
      background-color: $color-electric-blue;
    }
    &:hover {
      color: $color-electric-blue-300;
      i::before {
        background-color: $color-electric-blue-300;
      }
    }
    &:active {
      color: $color-electric-blue-500;
      i::before {
        background-color: $color-electric-blue-500;
      }
    }
    &:disabled {
      color: $color-electric-blue-100;
      i::before {
        background-color: $color-electric-blue-100;
      }
    }
  }
}

%ButtonSimple {
  border: 2px solid $color-electric-blue;
  color: $color-electric-blue;
  @extend %ButtonBase;
  i::before {
    background-color: $color-electric-blue;
  }
  &:hover {
    border-color: $color-blue-navy;
    color: $color-blue-navy;
    i::before {
      background-color: $color-blue-navy;
    }
  }
  &:active {
    border-color: $color-electric-blue-500;
    color: $color-electric-blue-500;
    i::before {
      background-color: $color-electric-blue-500;
    }
  }
  &:disabled {
    border-color: $color-electric-blue-100;
    color: $color-electric-blue-100;
    i::before {
      background-color: $color-electric-blue-100;
    }
  }
}

.btn-simple-gray {
  &,
  &:link {
    border-radius: 20px;
    min-height: 50px;
    padding: 0 8px;
    @extend %ButtonSimple;
    border: 2px solid $color-mirage-400;
    color: $color-mirage-400;
    i::before {
      background-color: $color-mirage-400;
    }
  }
}

.btn-simple {
  &,
  &:link {
    border-radius: 20px;
    min-height: 50px;
    padding: 0 8px;
    @extend %ButtonSimple;
  }
}

.btn-simple-light {
  &,
  &:link {
    border-radius: 16px;
    min-height: 40px;
    padding: 0 1px;
    @extend %ButtonSimple;
  }
}

.btn--block {
  display: flex;
  width: 100%;
}