.trust-section {
  background-color: $color-white;
  @extend %PaddingSections;
}

.trust {
  text-align: center;

  &__logos--no-slider {
    .splide__list {
      justify-content: center;
    }
  }
  &__title{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: $fsize-heading-2;
    @include media('laptop') {
      font-size: $fsize-heading-2-dt;
    }
  }

  &__logo {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media('tablet-s') {
      height: 110px;
    }
    img {
      width: 100px;
      height: 39px;
      object-fit: contain;
      filter: brightness(1.4) contrast(1.6) opacity(0.4) saturate(0);
      transition: all .2s ease-in;

      &:hover {
        filter: brightness(1) contrast(1) opacity(1) saturate(1);
      }

      @include media('tablet-s') {
        width: 170px;
      }

      @include media('tablet') {
        height: 56px;
        width: 256px;
      }
    }
  }
}