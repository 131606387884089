.big-title {
  padding-top: 25px;
  @include media('tablet') {
    padding-top: 40px;
  }
  &__container {
    padding: 76px 32px 68px;
    background-color: $color-electric-blue-100;
    border-radius: $br-l;
    @include media('tablet') {
      padding: 80px 120px;
    }
  }

  &__title {
    line-height: 44.2px;
    font-size: $fsize-heading-1;
    margin-bottom: 0;
    @include media('tablet') {
      font-size: $fsize-heading-xxl;
      line-height: 63.8px;
    }
  }
}