$breakpoints: (
  "phone": (
    min-width: 375px,
  ),
  "phone-l": (
    min-width: 640px,
  ),
  "tablet-s": (
    min-width: 764px,
  ),
  "tablet": (
    min-width: 968px,
  ),  
  "tablet-l": (
    min-width: 1024px,
  ),
  "laptop": (
    min-width: 1200px,
  ),
  "desktop": (
    min-width: 1360px,
  ), 
  "max-phone": (
    max-width: 374px,
  ),
  "max-tablet-s": (
    max-width: 764px,
  ),
  "max-tablet": (
    max-width: 968px,
  ),
  "max-tablet-l": (
    max-width: 1024px,
  ),
  "max-laptop": (
    max-width: 1200px,
  )
) !default;

@mixin media($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
  + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin flexbox($direction, $align, $justify) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

