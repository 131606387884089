.error-404 {
  text-align: center;
  @extend %PaddingSections;

  &__wrapper {
    max-width: 522px;
    @include media('tablet') {
      padding: 48px 0;
    }
  }

  &__title {
    color: $color-electric-blue;
  }

  &__button {
    display: inline-block;
    margin-top: 12px;
  }
}