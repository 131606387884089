@font-face {
  font-family: "Codec Pro";
  src: url("/src/fonts/CodecPro-Bold.eot");
  src: url("/src/fonts/CodecPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/CodecPro-Bold.woff") format("woff"),
    url("/src/fonts/CodecPro-Bold.ttf") format("truetype"),
    url("/src/fonts/CodecPro-Bold.svg#CodecPro-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Codec Pro";
  src: url("/src/fonts/CodecPro-ExtraBold.eot");
  src: url("/src/fonts/CodecPro-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/CodecPro-ExtraBold.woff") format("woff"),
    url("/src/fonts/CodecPro-ExtraBold.ttf") format("truetype"),
    url("/src/fonts/CodecPro-ExtraBold.svg#CodecPro-ExtraBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/src/fonts/Lato-Regular.eot");
  src: url("/src/fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/Lato-Regular.woff") format("woff"),
    url("/src/fonts/Lato-Regular.ttf") format("truetype"),
    url("/src/fonts/Lato-Regular.svg#Lato-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/src/fonts/Lato-Bold.eot");
  src: url("/src/fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/Lato-Bold.woff") format("woff"),
    url("/src/fonts/Lato-Bold.ttf") format("truetype"),
    url("/src/fonts/Lato-Bold.svg#Lato-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
