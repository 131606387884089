.header-nav {
  height: 100%;
  display: flex;
  align-items: center;
  @include media("laptop"){
    width: 100%;
  }

  &__toggle {
    position: relative;
    width: 48px;
    height: 25px;
    padding: 0px;
    border: none;
    margin: 0px auto;
    background: transparent;
    outline: unset;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    @include media('laptop') {
      display: none;
    }

    span {
      position: absolute;
      left: 0px;
      display: block;
      width: calc( 100% );
      height: 2px;
      background: $color-blue-navy;
      border-radius: 2px;
      opacity: 1;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }

    span:nth-child(2) {
      top: 11.5px;
      transform-origin: left center;
    }

    span:nth-child(3) {
      top: 23px;
      transform-origin: left center;
    }

    &[data-active="true"] {
      span {
        width: 22px;
      }
      span:nth-child(1) {
        transform: rotate(45deg);
        top: 4px;
        left: 15px;
      }

      span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      span:nth-child(3) {
        transform: rotate(-45deg);
        top: 20px;
        left: 15px;
      }
    }
  }

  &__menu-container {
    position: fixed;
    top: 78px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-white;
    transform: translateX(-100%);
    transition: all .2s ease-out;

    &[data-active="true"] {
      transform: translateX(0%);
    }

    @include media('laptop') {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      background-color: transparent;
      transform: translateX(0%);
      width: 100%;
    }
  }

  &__menu {
    list-style-type: none;
    margin: 0 0 18px;
    padding: 0;
    height: 65vh;
    overflow-y: scroll;

    a {
      text-decoration: none;
    }

    @include media('laptop') {
      display: flex;
      height: auto;
      overflow-y: unset;
      margin: 0 auto;
      position: relative;

      .btn-simple-light {
        display: none;
      }
    }
  }

  &__cta {
    position: absolute;
    bottom: 32px;
    margin: 0 22px;
    width: calc( 100% - (22px * 2));

    .btn-primary {
      display: flex;
      width: 100%;
    }

    @include media('laptop') {
      position: relative;
      bottom: unset;
      margin: 0;
      width: auto;

      .btn-primary {
        &, &:link {
          background-color: transparent;
          color: $color-electric-blue;
          display: inline-flex;
          width: auto;
  
          &:hover {
            background-color: transparent;
            border-color: $color-electric-blue-300;
            color: $color-electric-blue-300;
          }
          &:active {
            background-color: transparent;
            border-color: $color-electric-blue-500;
            color: $color-electric-blue-500;
          }
        }
      } 
    }
  }
}

.menu-item {
  a {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid $color-mirage-200;
    margin-top: 18px;
    color: $color-blue-navy;
    font-size: $fsize-heading-3;
    font-family: $ffamily-heading;
    font-weight: $fweight-heading-regular;

    @include media('laptop') {
      font-size: $fsize-small;
      border: none;
      margin-top: 0;
      height: 100px;
      padding: 0px 24px;
      font-family: $ffamily-general;
      font-weight: $fweight-regular;
    }

    > i {
      font-size: 20px;
      display: none;
      margin-left: 6px;
      transition: all .2s ease-in-out;

      @include media('laptop') {
        display: inline-flex;
      }
    }

    
  }

  .btn-simple-light {
    width: 48px;

    &:hover,
    &:active 
    {
      border-color: $color-electric-blue;
      i::before {
        background-color: $color-electric-blue;
      }
    }
    i {
      font-size: 1.5rem;
    }
  }

  &[data-active="true"] {
    a {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .btn-simple-light {
        margin-right: 10px;
        transform: rotate(180deg);
      }
    }
  }

  &.hidden {
    display: none;
  }

  &__submenu {
    position: absolute;
    height: 0;
    padding: 0;
    margin: 0;
    background-color: $color-white;
    transform: scaleY(0);

    @include media('laptop') {
      top: 100%;
      left: 50%;
      display: none;
      grid-template-columns: 1fr 1fr;
      box-sizing: border-box;
      // width: calc(100vw - 40vw);
      min-width: 516px;
      height: auto;
      padding: 40px;
      margin-top: 16px;
      background-color: $color-white;
      column-gap: 30px;
      row-gap: 45px;
      transform: scaleY(1) translateX(-50%);
      border-radius: $br-m;
      width: auto;

      &::after {
        content: '';
        position: absolute;
        top: -28px;
        width: 100%;
        height: 48px;
        background-color: transparent;
      }
    }

    &[data-active="true"] {
      position: relative;
      height: auto;
      transform: scaleY(1);
    }
  }

  &:hover {
    &.has-submenu {
      > a {
        @include media('laptop') {
          &::before {
            content: '';
            position: absolute;
            top: 88px;
            left: 50%;
            width: 0;
            height: 0;
            border-top: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 14px solid $color-white;
            border-left: 14px solid transparent;
            transform: translateX(-50%);
          }
        }
      }
    }
    
    a {
      &> i {
        transform: rotate(180deg);
      }
    }
    .menu-item__submenu {
      @include media('laptop') {
        display: grid;
      }
    }
  }

  
}

.submenu-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 24px 16px;
  margin-top: 18px;
  border-bottom: 1px solid $color-mirage-200;

  @include media('laptop') {
    border: none;
    padding: 0;
    margin-top: 0;
    min-width: 360px;
  }

  &__icon {
    margin-right: 18px;

    img {
      width: 48px;
      height: 48px;
      object-fit: contain;
    }
  }

  &__title {
    display: block;
    margin-bottom: 8px;
    color: $color-blue-navy;
    font-size: $fsize-heading-4;
    line-height: 1.3;
    @extend %HeadingFontRegular;
  }

  &__menu {
    margin: 0;
    padding: 0;
    list-style: none;
    li > a {
      height: auto;
      padding: 0;
      border: none;
      margin: 0;
      font-family: $ffamily-general;
      font-size: $fsize-small;
      font-weight: $fweight-regular;
      transition: all .2s ease-in-out;
      &:hover {
        color: $color-electric-blue;
      }
    }
  }
}

.header--sticky {
  .header-nav__toggle {
    span {
      background-color: $color-blue-navy !important;
    }
  }

  @include media('laptop') {
    .menu-item > a {
      color: $color-blue-navy !important;
    }
  
    .header-nav__cta .btn-primary {
      &,
      &:link { 
        background-color: transparent !important;
        border-color: $color-electric-blue !important;
        color: $color-electric-blue !important;
        i::before {
          background-color: $color-electric-blue !important;
        }
        &:hover {
          border-color: $color-electric-blue-300 !important;
          color: $color-electric-blue-300 !important;
          i::before {
            background-color: $color-electric-blue-300 !important;
          }
        }
        &:active {
          border-color: $color-electric-blue-500 !important;
          color: $color-electric-blue-500 !important;
          i::before {
            background-color: $color-electric-blue-500 !important;
          }
        }
        &:disabled {
          border-color: $color-electric-blue-100 !important;
          color: $color-electric-blue-100 !important;
          i::before {
            background-color: $color-electric-blue-100 !important;
          }
        }
      }
    }
  }
}

.header--dark,
.header--yellow,
.header--light {
  .header-nav__cta .btn-primary {
    &,
    &:link {
      background-color: $color-electric-blue;
      border-color: $color-electric-blue;
      color: $color-white;
      i::before {
        background-color: $color-white;
      }
      &:hover {
        background-color: $color-electric-blue-300;
        border-color: $color-electric-blue-300;
      }
      &:active {
        background-color: $color-electric-blue-500;
        border-color: $color-electric-blue-500;
      }
      &:disabled {
        background-color: $color-electric-blue-100;
        border-color: $color-electric-blue-100;
      }
    }
  }
}

.header--dark {
  .header-nav__toggle {
    span {
      background-color: $color-white;
    }
  }

  @include media('laptop') {
    .menu-item > a {
      color: $color-white;
    }
  
    
  }

  
}


