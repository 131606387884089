.single-post {
  &__wrapper {
    @include media('tablet') {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &__sidebar {
    &.relative {
      position: relative;
    }
    @include media('tablet') {
      width: 380px;
      margin-right: 42px;
    }
  }

  &__fixed {
    transition: all .2s ease-in-out;
    @include media('tablet') {
      &.true {
        position: fixed;
        width: 380px;
        top: 180px;
        height: 76vh;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 12px;
        }

        &.to-bottom {
          position: absolute;
          bottom: 48px;
          top: unset;
        }
      }
    }
  }

  &__meta {
    font-size: $fsize-xsmall;
    color: $color-blue-navy;
    @extend %GeneralFontRegular;
  }

  &__content {
    @include media('tablet') {
      width: calc( 100% - (380px + 42px));
    }
  }

  &__post {
    font-size: $fsize-general;

    figure {
      max-width: calc(100%) !important;
    }
    img {
      max-width: calc(100%);
    }
    @include media('laptop') {
      font-size: $fsize-small;
    }
  }

  &__banner {
    display: flex;
    justify-content: center;
    
  }

  &__banner-image {
    border-radius: $br-m;
    width: 100%;
  }
}