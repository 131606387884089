.overflow-top {
  position: relative;
  z-index: 2;
}

.padding-section {
  padding-top: $vgutter;
  padding-bottom: $vgutter;

  @include media('laptop') {
    padding-top: $vgutter-lg;
    padding-bottom: $vgutter-lg;
  }
}

.padding-side {
  padding-left: 24px !important;
  padding-right: 24px !important;

  @include media('desktop') {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.just-mobile {
  display: block;
  @include media('tablet') {
    display: none;
  }
}

.just-desktop {
  display: none;
  @include media('tablet') {
    display: block;
  }
}