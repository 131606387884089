.testimonials {
  @extend %PaddingSections;

  &__title {
    text-align: center;
    margin-bottom: 45px;
    &--with-bar{
      @extend %YellowBarHeading2Before;
    }
    @include media('laptop') {
      margin-bottom: 49px;
    }
  }

  &__slider {
    .splide__arrows {
      position: relative;
      margin-top: 40px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .splide__arrow {
      @extend %ButtonSimple;
      width: 59px;
      height: 50px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $br-s;
      top: unset;
      bottom: 0px;
      transform: translateY(0%);
      opacity: 1;
      position: relative;
      i {
        font-size: 24px;
      }

      &--next {
        right: 19.5px;
      }

      &--prev {
        left: 19.5px;
      }
    }
    .splide__pagination {
      position: relative;
      display: flex;
      min-height: 50px;
      align-items: center;
      bottom: unset;
      margin: 0px 29px;

      li {
        display: inline-flex;
      }
    }

    .splide__pagination__page {
      height: 6px;
      width: 32px;
      background-color: $color-mirage-400;
      border-radius: 3px;
      transform: scale(1);
      opacity: 1;

      &.is-active {
        background-color: $color-electric-blue;
        transform: scale(1);
      }
    }
  }
}

.testimonial {
  padding: 40px 32px;
  border: 1px solid $color-mirage-200;
  border-radius: $br-m;
  @include media('laptop') {
    padding: 60px 94.5px;
  }

  &__content {
    margin-bottom: 32px;
    position: relative;
    font-weight: $fweight-bold;
    font-size: $fsize-heading-4-dt;

    @include media('laptop') {

    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      background-image: url('../../static/testimonial-icon.svg');
      background-size: cover;
      width: 84.17px;
      height: 66.43px;
      z-index: -1;
    }

    &::before {
      top: 16px;
      left: -18px;
      @include media('laptop') {
        top: -33px;
        left: -61.58px;
      }
    }

    &::after {
      bottom: -50px;
      right: -21px;
      transform: rotate(180deg);
      @include media('laptop') {
        bottom: -33.86px;
        right: -71.58px;
      }
    }
  }

  &__meta {
    @include media('tablet-s') {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__customer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 32px;
    @include media('tablet-s') {
      margin-bottom: 0;
      margin-right: 57px;
    }
  }

  &__photo {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    margin-right: 17px;
    background-color: $color-electric-blue-200;

    img {
      width: 75px;
      height: 75px;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  &__name {
    font-weight: $fweight-bold;
    font-size: $fsize-small;
  }

  &__job {
    display: block;
    font-weight: $fweight-regular;
    font-size: $fsize-xsmall;
  }

  &__company {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75px;
    @include media('tablet-s') {
      justify-content: flex-start;
      width: auto;
    }

    img {
      height: 75px;
      width: 175px;
      object-fit: contain;
    }
  }
}