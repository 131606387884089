.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  @include media('tablet') {
    margin-top: 48px;
    justify-content: flex-end;

    &--min-padding {
      margin-top: 24px;
    }
  }

  &__prev {
    margin-right: 16px;
  }

  &__next {
    margin-left: 16px;
  }

  &__pages {
    display: flex;
    align-items: center;
    height: 50px;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-left: 2px;
      margin-right: 2px;
    }
    li > span,
    li > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background-color: transparent;
      transition: all .2s ease-in-out;
      text-decoration: none;
      color: $color-blue-navy;
    }

    li > a:hover,
    li > a[data-active="true"],
    li > span {
      background-color: $color-radiant-yellow;
    }
  }
}