.summary {
  margin-bottom: 42px;

  &.hidden {
    display: none;
  }

  &__title {
    position: relative;
    display: inline-block;
    font-size: $fsize-heading-2-dt;
    margin: 0 0 24px;
    line-height: 36.4px;
    @extend %HeadingFontRegular;

    &--highlight{
      &::before {
        display: block;
        position: absolute;
        content: '';
        width: 97px;
        height: 13px;
        bottom: 4px;
        right: 0;
        background-color: $color-radiant-yellow;
        z-index: -1;
      }
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    margin-bottom: 24px;
  }

  &__link {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    padding: 18px 24px;
    border: 2px solid $color-mirage-300;
    background-color: $color-mirage-100;
    color: $color-blue-navy;
    font-size: $fsize-heading-4-dt;
    border-radius: $br-m;
    line-height: 24px;
    text-decoration: none;
    transition: all .2s ease-in-out;
    @extend %HeadingFontRegular;

    &:hover,
    &:active {
      border-color: $color-electric-blue;
      color: $color-electric-blue;
    }

    @include media('tablet') {
      align-items: flex-start;
    }

    i {
      margin-right: 10px;
      &::before {
        background-color: $color-electric-blue;
      }
    }
  }
}