.post-tags {
  list-style: none;
  padding-left: 0;
  margin: 0 0 26px;

  &__item {
    padding-bottom: 16px;
  }

  .post-tag,
  a {
    font-size: $fsize-xsmall;
    color: $color-electric-blue;
    text-decoration: none;
    @extend %GeneralFontRegular;
  }

  .post-tag {
    display: inline-flex;
    padding: 4px 10px;
    align-items: center;
    border: 1px solid $color-electric-blue;
    border-radius: 15px;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%
    }
  }
}