.popup-video {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 315px;
  height: 177px;
  padding: 30px;
  background-color: $color-white;
  transform: translate(-50%, -50%);
  border-radius: $br-s;
  
  @include media('tablet-s') {
    width: 640px;
    height: 360px;
  }
  
  @include media('tablet') {
    width: 854px;
    height: 480px;
  }
  
  @include media('desktop') {
    width: 1280px;
    height: 720px;
  }

  video {
    width: 100%;
    height: 100%;
  }

  &__close {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 28px;
    height: 28px;
    border: none;
    background-color: transparent;
    color: $color-blue-navy;
    cursor: pointer;
    i {
      font-size: 1.5rem;
    }
  }

  &__overlay {
    position: fixed;
    z-index: 99999;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba($color-blue-navy, 0.7);
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  
}