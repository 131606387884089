.sixthree-grid {
  position: relative;
  @extend %PaddingSections;

  &__title {
    text-align: center;
    margin-bottom: 28px;
    font-size: $fsize-heading-2-dt;
  }

  &-light {
    background-color: $color-electric-blue-100;

    .sixthree-grid__title {
      color: $color-electric-blue;
    }
  }

  &-dark {
    background-color: $color-blue-navy;

    .sixthree-grid__title {
      color: $color-white;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0;
    z-index: 1;
    @include media ('tablet') {
      opacity: .3;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: -28px;
    @include media ('tablet-s') {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .card-1 {
      margin-bottom: 28px;
      border-radius: 16px;
      @include media ('tablet-s') {
        width: calc( (100% - (35px*1)) /2);
      }
      @include media ('laptop') {
        width: calc( (100% - (35px*2)) /3);
      }
    }
    
  }

  .wrapper-large {
    position: relative;
    z-index: 2;
  }
}