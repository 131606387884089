main > *:last-child {
  @include media("tablet") {
    padding-bottom: 300px !important;
    margin: 0;
  }  
  @include media("phone-l") {
    padding-bottom: 400px !important;
    margin: 0;
  }
  padding-bottom: 500px !important;
  margin: 0;
}

main.no-footer > *:last-child {
  padding-bottom: 50px !important;
  margin: 0;

  @include media("phone-l") {
    padding-bottom: 50px !important;
    margin: 0;
  }

  @include media("tablet") {
    padding-bottom: 80px !important;
    margin: 0;
  } 

}
