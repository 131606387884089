.square-cards {
  background-image: linear-gradient(to bottom, $color-white, $color-white-gradient);
  @extend %PaddingSections;

  &__title {
    max-width: 577px;
    margin-right: auto;
    margin-bottom: 48px;
    margin-left: auto;
    text-align: center;
  }

  &__cards {
    @include media('laptop') {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: center;
      margin-right: -12.5px;
      margin-left: -12.5px;
    }
  }

  &__buttons {
    margin-top: 48px;
    text-align: center;
  }
}

.square-card {
  position: relative;
  min-height: 220px;
  border: 1px solid $color-mirage-300;
  background-color: $color-mirage-50;
  border-radius: $br-s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  cursor: pointer;
  @extend %BoxShadow;

  @include media('laptop'){
    min-height: 261px;
    width: calc( (100% - (12.5px * 8)) / 4 );
    margin-right: 12.5px;
    margin-bottom: 0;
    margin-left: 12.5px;
  }

  &__picture {
    object-fit: contain;
    display: block;
    padding: 24px;
    width: 100%;
  }

  &__image {
    max-width: 233px;
    width: 100%;
  }

  &__content {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px;
    background-color: $color-electric-blue;
    color: $color-white;
    font-weight: $fweight-bold;
    visibility: hidden;
    opacity: 0;
    border-radius: $br-s;
    transition: all .2s ease-in-out;
    overflow: hidden;

    * {
      font-size: 1rem;
      line-height: 1.2;
      margin-top: 0px;
      margin-bottom: 0px;
      width: 100%;
      word-break: break-word;
      margin-bottom: 12px;
    }

    ul, ol {
      padding-left: 22px;
    }
  }
  
  &:hover,
  &:active {
    .square-card__content {
      visibility: visible;
      opacity: 1;
    }
  }
}
