//* Mosaic for dual section
.mosaic {
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    .mosaic__picture:nth-child(2) .mosaic__image,
    .mosaic__picture:nth-child(5) .mosaic__image,
    .mosaic__picture:nth-child(8) .mosaic__image {
      margin-top: 2rem;
    }
  }
  &__picture {
    width: 100%;
    height: auto;
    min-height: 160px;
    object-fit: cover;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $br-m;
  }


}
