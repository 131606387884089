.reference {
  &:not(:last-of-type) {
    margin-bottom: 25px;
  }

  @include media('tablet-s') {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--left {
      flex-direction: row-reverse;
    }

    &--right {
      flex-direction: row;
    }
  }

  &__picture {
    height: 238px;
    border-radius: $br-m;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend %BoxShadow;

    @include media('tablet-s'){
      margin-right: 54px;
      width: 340px;
    }

    @include media('laptop'){
      width: 440px;
      height: 300px;
    }
  }

  &__image {
    max-width: 100%;
  }

  &__info {
    margin-bottom: 32px;
    @include media('tablet-s'){
      margin-bottom: 0;
      padding: 32px 32px;
      width: calc( 100% - (340px + 54px) );
    }
     
    @include media('laptop'){
      padding: 56px 32px;
      width: calc( 100% - (440px + 54px) );
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__content {
    font-size: $fsize-small;
  }

  &__button {
    margin-top: 20px;

    .btn-primary {
      font-size: $fsize-small;
    }
  }

  &--right {
    .reference__picture {
      @include media('tablet-s'){
        margin-left: 54px;
        margin-right: 0;
      }
    }
  }
}