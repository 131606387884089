.cards-links {
  position: relative;
  text-align: center;
  @extend %PaddingSections;

  &--white {
    background-color: $color-white;
  }

  &--blue {
    background-color: $color-electric-blue-50;
  }

  &--gradient {
    background-image: linear-gradient(to bottom, $color-white, $color-electric-blue-50 50%);
  }

  &__title {
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    @include media("laptop") {
      flex-direction: row;
      justify-content: space-between;
      min-width: 498px;
      position: relative;
    }

    &--mobile {
      margin-top: 48px;
    }
  }

  &__tags {
    text-align: left;
  }

  &__tag {
    border: 1px solid $color-mirage-500;
    border-radius: $br-l;
    color: $color-mirage-500;
    font-size: $fsize-xsmall;
    padding: 4px 12px 6px;
    &:not(:last-child) {
      margin-right: 0.3rem;
    }
  }

  &__item {
    background-color: $color-white;
    border-radius: $br-s;
    box-shadow: 0px 12px 30px rgba(0, 65, 255, 0.08);
    overflow-x: hidden;
    padding-bottom: 1.5rem;
    width: 100%;
    @include media("laptop") {
      width: 32%;
    }
  }

  &__picture {
    width: 100%;
  }

  &__image {
    border-radius: inherit;
    width: 100%;
  }

  &__content {
    max-width: 570px;
    margin-top: 12px;
    margin-right: auto;
    margin-left: auto;
  }

  &__item-title {
    font-family: $ffamily-heading;
    font-size: $fsize-heading-3-dt;
    font-weight: $fweight-heading-regular;
    line-height: 1;
    margin-bottom: 0;
    padding-top: 1rem;
    text-align: left;
  }

  &__excerpt {
    p {
      font-family: $ffamily-general;
      font-size: $fsize-general;
      font-weight: $fweight-regular;
      line-height: 1.4;
      text-align: left;
    }
  }

  &__general-link {
    display: block;
    position: relative;
    margin-top: 3rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__author {
    align-items: center;
    display: flex;
    img {
      height: 40px;
      width: 40px;
      margin-right: 0.7rem;
    }
    .post {
      &__info {
        display: flex;
        flex-direction: column;
        width: auto;
        align-items: start;
      }
      &__author {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
      }
      &__date {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #5f6d84;
      }
    }
  }

  &__link {
    a {
      width: 59px;
      height: 50px;
      border: 2px solid #0041ff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $br-m;
      padding: 0;
      @extend %ButtonSimple;
      i {
        vertical-align: middle;
        font-size: 1.25rem;
        font-weight: bold;
      }
    }
  }

  .splide__track{
    padding-bottom: 48px;
  }
  .splide__slide{
    padding: 0 1rem;
  }

  .splide__pagination {
    margin-top: 20px;
    .splide__pagination__page {
      background-color: #ccd3df !important;
      height: 8px !important;
      width: 8px !important;

      &.is-active {
        background-color: $color-electric-blue !important;
        height: 8px !important;
        width: 8px !important;
      }
    }
  }
}
