.card-image {
  display: block;
  border-radius: $br-s;
  overflow: hidden;
  background-color: $color-white !important;
  text-decoration: none;
  &__image {
    width: 100%;
  }

  &__title {
    color: $color-blue-navy;
    font-size: $fsize-heading-4;
    line-height: 24px;
    margin-bottom: 12px;
    @extend %HeadingFontRegular;
  }

  &__content {
    padding: 24px 24px 8px;
    color: $color-mirage-600;
    font-size: $fsize-general;

    @include media('laptop') {
      font-size: $fsize-small;
    }
  }
}