.text-logos {
  background-image: linear-gradient(to bottom, $color-white-gradient, $color-white);
  @extend %PaddingSections;

  .wrapper-medium {
    padding-left: 24px;
    padding-right: 24px;
  }

  &__title {
    text-align: center;
    margin-bottom: 12px;

  }

  &__subtitle {
    text-align: center;
    margin-bottom: 48px;
    font-size: $fsize-small;
    font-weight: $fweight-regular;
  }

  &__blocks {
    
  }
}

.text-logo {
  position: relative;
  margin-bottom: 48px;
  &:last-of-type {
    margin-bottom: 0;
  }

  @include media('tablet-s') {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 81.11px;
    height: 132.5px;
    background-image: url('../../static/pattern-line.svg');
    background-size: cover;
    z-index: 1;
  }

  &::before {
    top: -32px;
    left: 0px;

    @include media('laptop') {
      top: -74px;
      left: -81.11px;
    }
  }

  &--reversed {
    @include media('tablet-s') {
      flex-direction: row-reverse;

      &::before {
        top: unset;
        left: unset;
        bottom: -74px;
        right: -81.11px;
      }
    }

    &::before {
      @include media('laptop') {
        top: unset;
        left: unset;
        bottom: -74px;
        right: -81.11px;
      }
    }
  }

  &__body {
    position: relative;
    z-index: 2;
    padding: 40px 24px;
    background-color: $color-white;
    margin-bottom: 24px;
    border-radius: $br-xm;
    @include media('tablet-s') {
      padding: 40px;
      width: calc( 100% - (318px + 40px) );
      margin-bottom: 0px;
    }

    @include media('laptop') {
      width: calc( 100% - (440px + 40px) );
    }
  }

  &__title {
    margin-bottom: 28px;
  }

  &__buttons {
    margin-top: 24px;
  }

  &__media {
    position: relative;
    z-index: 2;
    border-radius: $br-m;
    overflow: hidden;
  }

  &__picture {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    object-fit: contain;
  }

  &__image{
    border-radius: 20px;
    object-fit: cover;
    width: 100%;
    height: 236px;
    @include media('tablet-s') {
      width: 318px;
      height: 236px;
    }
    @include media('laptop') {
      width: 440px;
      height: 318px;
    }
  }
}