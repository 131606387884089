.author {
  margin-bottom: 42px;

  &__title {
    display: block;
    margin-bottom: 10px;
    color: $color-mirage-600;
    font-size: $fsize-xsmall;
    @extend %GeneralFontRegular;
  }

  &__profile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  &__photo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  &__image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border: 100%;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: $fsize-small;
    line-height: 21px;
    color: $color-blue-navy;
    @extend %GeneralFontBold;
  }

  &__job-title {
    margin-top: 4px;
    font-size: $fsize-xsmall;
    color: $color-mirage-600;
    @extend %GeneralFontRegular;
  }
}