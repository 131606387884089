%BoxShadow {
  box-shadow: 0px 12px 30px rgba(0, 65, 255, 0.08);
}

%PaddingSections {
  padding-top: $vgutter;
  padding-bottom: $vgutter;

  @include media('laptop') {
    padding-top: $vgutter-lg;
    padding-bottom: $vgutter-lg;
  }
}

%HeadingHighlighter {
  span {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    background-image: linear-gradient(to bottom, transparent 55%, $color-radiant-yellow 50%)
  }
}

%YellowBarHeading2 {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    right: 50%;
    bottom: 3px;
    width: 144px;
    height: 13px;
    background-color: $color-radiant-yellow;
    transform: translateX(50%);

    @include media('tablet-s') {
      right: 0;
      width: 177px;
      transform: translateX(0%);
    }
  }
}


%YellowBarHeading2Before {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 20px;
    width: 144px;
    height: 13px;
    background-color: $color-radiant-yellow;

    @include media('tablet-s') {
      left: 0;
      width: 177px;
      transform: translateX(0%);
    }
  }
}

%YellowBarHeading1 {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 26px;
    width: 126px;
    height: 13px;
    background-color: $color-radiant-yellow;
  }
}