.event-webinar {
  &__wrapper {
    @include media('tablet') {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &__sidebar {
    @include media('tablet') {
      width: 148px;
      margin-right: 42px;
    }
  }

  &__content {
    @include media('tablet') {
      width: calc( 100% - (148px + 42px));
    }
  }

  &__data {
    display: flex;
    align-items: center;
  }

  &__date, &__place {
    display: flex;
    align-items: center;
    margin-right: 24px;

    i {
      margin-right: 8px;
    }

    i::before {
      background-color: $color-electric-blue;
    }
  }

  &__buttons {
    margin-top: 32px;
    text-align: center;
  }

  &__iframe {
    margin-top: 32px;
  }
}