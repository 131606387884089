.post-card {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  border-radius: $br-s;
  box-shadow: 0px 12px 30px rgba(0, 65, 255, 0.08);
  text-decoration: none;
  transition: all .2s ease-in-out;
  &:link {
    cursor: pointer;
  }

  &:hover {
    transform: scale(1.01) translateY(-5px);
  }

  &__picture {
    width: 100%;
    display: block;
    background-color: $color-mirage-300;
    line-height: 0;
    overflow: hidden;
    height: 192px;

    @include media('tablet') {
      height: 212px;
    }
  }

  &__image {
    border-radius: inherit;
    width: 100%;
    max-height: 212px;
    object-fit: cover;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 24px 16px 24px;
    flex-grow: 1;
    @include media('tablet') {
      padding: 24px 32px 24px;
    }
  }

  &__tags {
    text-align: left;
  }

  &__tag {
    border: 1px solid $color-mirage-500;
    border-radius: $br-l;
    color: $color-mirage-500;
    font-size: $fsize-xsmall;
    padding: 4px 12px 6px;
    text-decoration: none;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 0.3rem;
    }
  }

  &__title {
    font-size: $fsize-heading-3-dt;
    color: $color-blue-navy;
    line-height: 1;
    margin-bottom: 12px;
    padding-top: 1rem;
    text-align: left;
    @extend %HeadingFontRegular;
  }

  &__excerpt {
    margin-bottom: 1rem;
    color: $color-mirage-600;
    text-align: left;
    * {
      font-size: $fsize-general;
      @include media('laptop') {
        font-size: $fsize-small;
      }
    }
    p {
      font-family: $ffamily-general;
      font-weight: $fweight-regular;
      line-height: 1.4;
      text-align: left;
    }
  }

  &__text {
    * {
      font-size: $fsize-general;
      @include media('laptop') {
        font-size: $fsize-small;
      }
    }
  }

  &__general-link {
    display: block;
    position: relative;
    margin-top: 3rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: flex-end;
  }

  &__meta {
    align-items: center;
    display: flex;
    margin-right: 10px;
    min-height: 50px;
  }

  &__photo {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-right: 0.7rem;
    object-fit: cover;
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: start;
  }

  &__author {
    font-size: $fsize-small;
    line-height: 150%;
    color: $color-blue-navy;
    @extend %GeneralFontBold;
  }

  &__date {
    font-size: $fsize-xsmall;
    line-height: 14px;
    color: $color-mirage-600;
    display: inline-flex;
    align-items: center;
    @extend %GeneralFontRegular;
    i {
      font-size: 20px;
      margin-right: 8px;
      &::before {
        background-color: $color-electric-blue;
      }

      &:last-of-type {
        margin-left: 8px;
      }
    }
  }

  .btn-simple {
    i {
      font-size: 23px;
    }
  }

  &--resource-center {
    .post-card__excerpt {
      display: flex;
    }

    .post-card__text {
      margin-right: 16px;
    }

    .post-card__link {
      display: flex;
      align-items: center;
    }
  }

  &--event-webinar {
    .post-card__title {
      line-height: 24px;
      @include media('tablet') {
        min-height: 64px;
      }
    }
  }
}