.cards-images {
  position: relative;
  background-color: $color-blue-navy;
  @extend %PaddingSections;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('../../static/pattern.png');
    background-size: cover;
    opacity: 0;
    z-index: 1;
    @include media("tablet") {
      opacity: 0.1;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 2;
    padding-right: 24px;
    padding-left: 24px;
    @include media("laptop") {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__title, &__content {
    text-align: center;
    max-width: 577px;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    color: $color-white;
    margin-bottom: 12px;
  }

  &__content {
    color: $color-electric-blue-100;
    margin-bottom: 48px;
  }

  &__slider {
    padding-bottom: 50px;

    .splide__pagination {
      bottom: -50px;
      .splide__pagination__page {
        background-color: #ccd3df !important;
        height: 8px !important;
        width: 8px !important;
  
        &.is-active {
          background-color: $color-electric-blue !important;
          height: 8px !important;
          width: 8px !important;
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    @include media("tablet") {
      flex-direction: row;
      justify-content: center;
      position: relative;
      margin-left: -17px;
      margin-right: -17px;
    }
    .card-image {
      width: 100%;
      @include media("tablet") {
        margin-left: 17px;
        margin-right: 17px;
        width: calc( (100% - (17px * 4)) / 2);
      }
      @include media("laptop") {
        width: calc( (100% - (17px * 6)) / 3);
      }
      &:not(:last-of-type) {
        margin-bottom: 34px;
        @include media("tablet") {
          margin-bottom: 0px;
        }
      }
    }
  }
}