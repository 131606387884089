.team-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: $br-s;
  text-decoration: none;
  text-align: center;
  margin-bottom: 28px;
  background-color: $color-white;
  @extend %BoxShadow;

  @include media('tablet-s') {
    flex-direction: row;
    margin-left: 14px;
    margin-right: 14px;
    width: calc( (100% - (14px * 6)) / 3 );
    text-align: left;
  }

  &__picture {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media('tablet-s') {
      margin-bottom: 0px;
      margin-right: 20px;
      width: 137px;
    }
  }

  &__image {
    width: 137px;
    height: 137px;
    border-radius: 100%;
    object-fit: cover;
    background-color: $color-mirage-300;
  }

  &__content {
    @include media('tablet-s') {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__name {
    margin-bottom: 8px;
    color: $color-blue-navy;
    font-size: $fsize-heading-3-dt;
    line-height: 1.1;
    @extend %HeadingFontBold;
  }

  &__job-title {
    font-size: $fsize-small;
    line-height: 21px;
    color: $color-blue-navy;
    margin-bottom: 8px;
    @extend %GeneralFontBold;
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media('tablet-s') {
      justify-content: flex-start;
    }
  }

  &__social-icon {
    width: 32px;
    height: 32px;
    object-fit: cover;
    margin-left: 6px;
    margin-right: 6px;
    @include media('tablet-s') {
      margin-left: 0;
      margin-right: 12px;
    }
  }
}