html {
  font-size: $fsize-root;
}

body {
  font-family: $ffamily-general;
  color: $color-blue-navy;
  font-size: $fsize-general;
  line-height: 1.6;
  
  ol, ul {
    padding-left: 17px;
  }

  p {
    margin-top: 0;
  }

  blockquote {
    padding: 0px 24px;
    margin: 16px 0 0;
    font-weight: $fweight-bold;
    position: relative;
    font-size: $fsize-heading-4-dt;
    line-height: 28px;

    &::after,
    &::before {
      content: '';
      position: absolute;
      background-image: url('../../static/testimonial-icon.svg');
      background-size: cover;
      width: 84.17px;
      height: 66.43px;
      z-index: -1;
    }

    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      bottom: 0;
      right: 0;
      transform: rotate(180deg);
    }

    @include media('laptop') {
      padding: 16px 34px;
    }
  }
}

// Headings
%HeadingFontBold {
  font-family: $ffamily-heading;
  font-weight: $fweight-heading-bold;
}

%HeadingFontRegular {
  font-family: $ffamily-heading;
  font-weight: $fweight-heading-regular;
}

%GeneralFontBold {
  font-family: $ffamily-general;
  font-weight: $fweight-bold;
}

%GeneralFontRegular {
  font-family: $ffamily-general;
  font-weight: $fweight-regular;
}

[class^="heading"]{
  margin-top: 0;
  line-height: 1.3;
}

.heading-stadistic {
  font-size: $fsize-heading-stadistic;
  @extend %GeneralFontBold;
}

.heading-xxl {
  font-size: $fsize-heading-xxl;
  @extend %HeadingFontBold;
}

.heading-xl {
  font-size: $fsize-heading-xl;
  line-height: 62.4px;
  @extend %GeneralFontBold;
}

.heading-key {
  font-size: $fsize-heading-key;
  @extend %HeadingFontRegular;
  @include media('laptop') {
    font-size: $fsize-heading-key;
  }
}

.heading-l {
  font-size: $fsize-heading-l;
  @extend %GeneralFontBold;
}

.heading-big {
  font-size: $fsize-heading-big;
  @extend %HeadingFontBold;
}

.heading-1, h1 {
  font-size: $fsize-heading-1;
  @extend %HeadingFontBold;
  @include media('laptop') {
    font-size: $fsize-heading-1-dt;
  }
}

.heading-2, h2 {
  font-size: $fsize-heading-2-dt;
  @extend %HeadingFontRegular;
}

.heading-3, h3 {
  font-size: $fsize-heading-3;
  @extend %HeadingFontRegular;
  @include media('laptop') {
    font-size: $fsize-heading-3-dt;
  }
}

.heading-4, h4, h5, h6 {
  font-size: $fsize-heading-4;
  @extend %HeadingFontRegular;
  @include media('laptop') {
    font-size: $fsize-heading-4-dt;
  }
}

[class$="__title"] {
  @extend %HeadingHighlighter;
}

