.team {
  background-color: $color-mirage-100;
  @extend %PaddingSections;

  &__title, 
  &__content {
    max-width: 577px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__content {
    margin-bottom: 48px;
  }

  &__cards {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(0);
    height: 0;
    transition: all .2s ease-in-out;

    &[data-active="true"] {
      opacity: 1;
      visibility: visible;
      transform: translateX(0%) scale(1);
      height: auto;
    }
    @include media('tablet-s') {
      display: flex;
      margin-left: -14px;
      margin-right: -14px;
      flex-wrap: wrap;
    }
  }
}