.no-results {
  text-align: center;
  @extend %PaddingSections;

  &__wrapper {
    padding-left: 24px;
    padding-right: 24px;

    @include media('laptop') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__title,
  &__content {
    max-width: 605px;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    margin-bottom: 34px;
    font-size: $fsize-heading-1-dt;
  }

  &__content {
    font-size: $fsize-small;
  }
}