.cards-simples {
  text-align: center;
  @extend %PaddingSections;

  &__title {
    margin-bottom: 38px;
    span {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      background-image: linear-gradient(to bottom, transparent 55%, $color-radiant-yellow 50%)
    }
    @include media('tablet-s') {
      margin-bottom: 28px;
    }
  }

  &__cards {
    margin-bottom: -28px;
    @include media('tablet-s') {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: -14px;
      margin-left: -14px;
    }
  }
}

.card-simple {
  padding: 32px 29.5px;
  border: 1px solid $color-mirage-300;
  margin-bottom: 28px;
  background-color: $color-white;
  border-radius: $br-s;
  box-sizing: border-box;
  @extend %BoxShadow;

  @include media('tablet-s') {
    margin-left: 14px;
    margin-right: 14px;
    width: calc( (100% - (14px * 6)) / 3);
  }

  &__picture {
    margin-bottom: 24px;
  }

  &__icon {
    width: 48px;
    height: 48px;
  }
  &__title {
    margin-bottom: 8px;
    color: $color-electric-blue;
    font-size: $fsize-heading-3-dt;
    min-height: 52px;
  }

  &__content { 
    font-size: $fsize-general;
    color: $color-mirage-600;
    @include media('tablet-s') {
      font-size: $fsize-small;
    }
  }
}
