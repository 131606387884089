.video {
  @extend %PaddingSections;

  &__title {
    text-align: center;
    margin-bottom: 32px;
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    max-width: 742px;
    height: auto;
    overflow: hidden;
    position: relative;
    border-radius: $br-m;

    @include media('laptop'){
      border-radius: $br-xm;
    }
  }

  &__media {
    width: 100%;
    height: auto;
  }

  &__overlay {
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 1;
    visibility: visible;
    transition: all .2s ease-in-out;

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__play {
    display: block;
    width: 72px;
    height: 72px;
    background-image: url('../../static/play.svg');
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}