footer {
  position: relative;
  z-index: 10;
}

.footer {
  background-color: $color-blue-navy;
  padding-bottom: 1.5rem;
  padding-top: 6.5rem;
  position: relative;


  @include media("tablet-s"){
    padding-left: 0;
    padding-right: 0;
  }  

  &__bottom-section {
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 6rem;
    @include media("tablet-s") {
      flex-direction: row;
    }
  }

  &__menus-container {
    @include media("tablet") {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 0px;
    }
  }

  &__site-info {
    align-items: start;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    img {
      width: 190px;
      height: auto;
      margin-bottom: 10px;
    }
    p {
      color: $color-white;
      font-family: "Lato";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      margin: 0;
    }
  }
  &__menu-title {
    color: $color-white;
    font-family: "Lato";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 20px;
    margin-top: 0;
    @include media('laptop') {
      margin-bottom: 32px;
    }
  }
  &__menu-subtitle {
    color: $color-white;
    font-family: "Lato";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
  &__menu-list {
    list-style: none;
    margin-top: 0;
    padding: 0;
  }

  &__menu-link {
    position: relative;
    margin-top: 0;
    color: $color-mirage-400;
    font-family: "Lato";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    line-height: 28.8px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-white;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform .3s ease-out;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }

  &__social {
    margin-top: 1rem;
    @include media('laptop') {
      margin-top: 5rem;
    }
  }

  &__social-list {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
  }

  &__social-item:not(:last-child) {
    margin-right: 1rem;
  }

  &__social-link {
    align-items: center;
    color: $color-white;
    display: flex;
    font-family: "Lato";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-decoration: none;
    img {
      height: 28px;
      margin-right: 0.3rem;
      width: 28px;
    }
  }

  &__top-wrapper {
    background-color: #ffca54;
    background-position: center;
    background-size: cover;
    border-radius: 80px;
    left: 0;
    padding-bottom: 53px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 30px;
    position: absolute;
    right: 0;
    top: -24rem;
    z-index: 2;
    @include media("laptop") {
      padding-bottom: 107px;
      padding-top: 61px;
    }
    @include media("phone-l") {
      top: -15rem;
    }
    &::before {
      background-color: #ffca54;
      border-radius: 80px 80px 0 0;
      content: "";
      height: 200px;
      left: 0;
      position: absolute;
      top: -46px;
      transform: skewY(5deg);
      width: 100%;
      z-index: -1;
    }
  }

  &__top-bg {
    height: calc(100% + 105px);
    left: 0;
    overflow: hidden;
    position: absolute;
    top: -110px;
    width: 100%;
    @include media("tablet") {
      height: calc(100% + 86px);
      transform: skewY(5deg);
      top: -46px;
      border-top-right-radius: 78px;
    }
    @include media("max-tablet-l"){
      display: none;
    }
    .bg-footer {
      display: none;
      height: 100%;
      opacity: 0.2;
      position: absolute;
      top: 0;
      width: 100%;
      @include media("tablet") {
        display: block;
        transform: skewY(-5deg);
        top: -10px;
        height: 92%;
      }

      @include media("laptop") {
        height: 94%;
      }
    }
  }

  &__top-section {
    align-items: safe;
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;
    z-index: 1;
    @include media("laptop") {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 5rem;
    }
  }

  &__top-left {
    text-align: left;
    width: 100%;
    @include media("laptop") {
      width: 50%;
      margin-right: 48px;
    }
    h1,
    h2,
    h3 {
      font-size: $fsize-heading-l;
      line-height: 130%;
      margin: 0 0 20px;
      @extend %GeneralFontBold;

      @include media("laptop") {
        font-size: $fsize-heading-xl;
      }
    }
    p {
      font-family: "Lato";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
  }

  &__top-right {
    text-align: left;
    width: 100%;
    @include media("laptop") {
      width: 50%;
    }
    p {
      font-size: 18px;
      line-height: 28px;
      @extend %GeneralFontRegular;
    }

    .btn-primary, .btn-secondary {
      width: auto;
    }
  }

  &__bg {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .footer__wrapper {
    padding-left: 32px;
    padding-right: 32px;

    @include media('laptop') {
      padding-left: 0;
      padding-right: 0;
    }
    hr {
      margin: 20px 0 32px;
      opacity: 0.5;
    }
  }

  &__copy-area {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    @include media("tablet-s") {
      align-items: center;
      flex-direction: row;
      justify-content: center;
    }
    p {
      color: $color-white;
      font-size: 12px;
      line-height: 130%;
      text-align: center;
      width: 100%;
      margin-bottom: 4px;
      @extend %GeneralFontBold;
      @include media("tablet-s") {
        text-align: left;
        width: auto;
        margin-right: 20px;
        margin-bottom: 0px;
      }
    }

    &-menu {
      display: block;
      width: 100%;
      @include media("tablet-s") {
        width: auto;
      }
      ul {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 0;
        @include media("tablet-s") {
          flex-direction: row;
          justify-content: space-between;
        }
        li {
          color: $color-white;
          font-size: 12px;
          line-height: 130%;
          margin-bottom: 4px;
          @extend %GeneralFontRegular;
          &:nth-child(2n) {
            display: none;
          }
          a {
            color: $color-white;
            font-size: 12px;
            line-height: 130%;
            @extend %GeneralFontRegular;
          }

          @include media("tablet-s") {
            margin-bottom: 0;
            padding-left: 10px;
            padding-right: 10px;
            &:nth-child(2n) {
              display: inline;
            }
          }
        }
      }
    }
  }

  &--just-copy {
    padding-top: 0;
    padding-bottom: 0;

    hr {
      display: none;
    }

    .footer__bottom-section {
      display: none;
    }

    .footer__copy-area {
      padding: 33px 0;
    }
  }
}
