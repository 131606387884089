html {
  @include media('laptop') {
    scroll-padding-top: 282px !important;
  }
}

.hero-section--start {
  background-position: 0% 60%;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: $br-l;
  border-bottom-right-radius: $br-l;
}
.hero--start {
  margin: 0 !important;
  padding: 70px 0 100px !important;
  position: relative;
  @include flexbox(row, center, center);
  @include media("tablet") {
    padding: 130px 0 192px !important;
  }
  .hero {
    &__content {
      @include flexbox(column, center, center);
      @include media("laptop") {
        max-width: 70%;
        margin-top: -3rem;
      }
    }

    &__title {
      /* Electric Blue/Electric Blue */
      color: $color-electric-blue;
      font-family: $ffamily-general;
      font-size: $fsize-heading-1;
      font-style: normal;
      font-weight: $fweight-bold;
      line-height: 130%;
      text-align: center;
      /* or 62px */

      text-align: center;
      width: 100%;
    }
    &__text {
      margin-bottom: 0;
      max-width: 100%;
      padding: 0;
      @include flexbox(row, center, center);
      p {
        color: $color-blue-navy;
        font-family: $ffamily-general;
        font-size: $fsize-heading-4-dt;
        font-style: normal;
        font-weight: $fweight-regular;
        line-height: 28px;
        text-align: center;
      }
    }
    &__buttons {
      margin-top: 37px;

      .btn-primary,
      .btn-secondary {
        width: 100%;

        @include media("laptop"){
          width: auto;
        }
      }

      .btn-primary:first-of-type,
      .btn-secondary:first-of-type {
        margin-right: 12px;
        margin-bottom: 14.88px;
      }
    }
  }
}

.start-cards {
  &-section {
    @extend %PaddingSections;
    .wrapper-medium {
      position: relative;
    }
  }
  &__container {
    margin-top: -100px;
    padding: 0rem 1rem;
    @include flexbox(column, center, center);
    @include media("tablet") {
      margin-top: -200px;
      @include flexbox(row, stretch, space-between);
    }
  }
  &__card {
    background: $color-mirage-50;
    border: 1px solid $color-mirage-300;
    border-radius: $br-s;
    box-shadow: 0px 12px 30px rgba(0, 65, 255, 0.08);
    margin-bottom: 1rem;
    padding: 32px;
    width: 100%;
    @include flexbox(column, center, flex-start);
    @include media("tablet") {
      width: 32%;
    }

    &-icon {
      height: 140px;
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 140px;
        width: 140px;
        object-fit: cover;
      }
    }

    &-content {
      h2 {
        font-family: $ffamily-heading;
        font-size: $fsize-heading-4;
        font-style: normal;
        font-weight: $fweight-heading-regular;
        line-height: 24px;
      }
      p {
        color: $color-mirage-600;
        font-family: $ffamily-general;
        font-size: $fsize-small;
        font-style: normal;
        font-weight: $fweight-regular;
        line-height: 150%;
      }
    }

    &-bottom {
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
      width: 100%;
    }
    &-link {
      margin-top: 1rem;
      @extend .btn-secondary;
      width: 100% !important;
    }
  }
}

.start-sticky {
  &__bar {
    background: $color-white;
    box-shadow: 0px 12px 30px rgba(0, 65, 255, 0.08);
    opacity: 0;
    padding: 24px 0px;
    position: fixed;
    transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
    width: 100%;
    z-index: 3;

    &.sticky{
      opacity: 1;
    }

    .splide__pagination{
      bottom: -18px !important;
      .splide__pagination__page {
        background-color: #ccd3df !important;
        height: 8px !important;
        width: 8px !important;
  
        &.is-active {
          background-color: $color-electric-blue !important;
          height: 8px !important;
          width: 8px !important;
        }
      }
    }
  }

  &__card-container {
    min-height: 124px;
    padding-top: 1rem;
    @include media("laptop") {
      @include flexbox(row, center, space-between);
    }
  }

  &__card {
    background: $color-mirage-50;
    border: 1px solid $color-mirage-300;
    border-radius: $br-m;
    color: $color-blue-navy;
    font-family: $ffamily-general;
    font-size: $fsize-heading-4-dt;
    font-style: normal;
    font-weight: $fweight-bold;
    line-height: 28px;
    padding: 16px 24px;
    text-decoration: none;
    @include media("laptop"){
      width: 32%;
    }
    @include flexbox(row, center, center);
    img {
      height: 44px;
      margin-right: 1rem;
      width: 44px;
    }
    i{
      font-size: 24px;
      margin-right: 1rem;
    }
  }
}

* {
  box-sizing: border-box;
}
