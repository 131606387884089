// WRAPPER
$wrapper-xlarge: 80%;
$wrapper-large: 1330px;
$wrapper-medium: 1120px;
$wrapper-xmedium: 1020px;
$wrapper-small: 930px;
$wrapper-mobile: 343px;

// SPACING
$vgutter: 50px;
$vgutter-lg: 80px;

// COLORS
// Primary colors
$color-electric-blue: #0041ff;

$color-electric-blue-50: #f2f6ff;
$color-electric-blue-100: #e7eefe;
$color-electric-blue-200: #cad7ff;
$color-electric-blue-300: #0037de;
$color-electric-blue-400: #0030bf;
$color-electric-blue-500: #012798;

// Secondary colors
$color-radiant-yellow: #ffca54;

$color-pastel-yellow: #fbeed1;
$color-sienna: #ffebdb;
$color-forest: #169f61;
$color-scarlet: #d93948;
$color-water-green: #1ae09a;

// Neutral colors
$color-blue-navy: #001944;
$color-white: #ffffff;
$color-white-gradient: #F2F6FF;

$color-mirage-50: #fafbfe;
$color-mirage-100: #f4f6fa;
$color-mirage-200: #eaedf6;
$color-mirage-300: #ccd3df;
$color-mirage-400: #acb5c3;
$color-mirage-500: #8d98aa;
$color-mirage-600: #5f6d84;
$color-mirage-700: #414d5e;
$color-mirage-800: #313a4a;

$color-white-gradient: #f5f7fd;

// FONTS
$ffamily-general: "Lato";
$ffamily-heading: "Codec Pro";

$fsize-root: 16px;
$fsize-general: 1rem;
$fsize-small: 0.875rem;
$fsize-xsmall: 0.75rem;

// Font Size Big Heading
$fsize-heading-stadistic: 3.75rem;
$fsize-heading-xxl: 3.625rem;
$fsize-heading-xl: 3rem;
$fsize-heading-l: 2.5rem;
$fsize-heading-big: 3.125rem;
$fsize-heading-key-dt: 2.75rem;
$fsize-heading-key: 1.5rem;

// Font Size H tags for mobile
$fsize-heading-1: 2.125rem;
$fsize-heading-2: 1.875rem;
$fsize-heading-3: 1.375rem;
$fsize-heading-4: 1.25rem;

// Font Size H tags for desktop > 1120
$fsize-heading-1-dt: 2rem;
$fsize-heading-2-dt: 1.75rem;
$fsize-heading-3-dt: 1.25rem;
$fsize-heading-4-dt: 1.125rem;

// Font Weight
$fweight-regular: 400;
$fweight-bold: 700;
$fweight-heading-regular: 500;
$fweight-heading-bold: 600;

// * New border radius
$br-l: 32px;
$br-xm: 30px;
$br-m: 20px;
$br-s: 16px;
$br-xs: 8px;
