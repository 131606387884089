.api-card {
  border-radius: $br-s;
  padding: 32px 24px;
  border: 1px solid $color-mirage-300;
  background-color: $color-white;
  text-align: left;
  display: block;
  text-decoration: none;
  transition: all .2s ease-in-out;
  @extend %BoxShadow;

  &:hover {
    transform: scale(1.01) translateY(-5px);
  }

  @include media('laptop') {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
    margin: 0;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    @include media('laptop') {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__image {
    border-radius: 100%;
    width: 137px;
    height: 137px;
    object-fit: cover;
    @include media('laptop') {
      width: 100px;
      height: 100px;
    }
  }

  &__title {
    margin-bottom: 12px;
    color: $color-blue-navy;
  }

  &__content {
    margin-bottom: 12px;
    color: $color-mirage-600;
    flex-grow: 1;
    @include media('laptop') {
      margin-bottom: 12px;
    }
    p{
      margin-top: 0;
      margin-bottom: 12px;
    }
  }

  &__link {
    width: 59px;
  }
}