.results {
  text-align: center;
  @extend %PaddingSections;

  &:nth-child(even) {
    background-color: $color-white;
  }
  &:nth-child(odd) {
    background-color: $color-electric-blue-50;
  }

  @include media('laptop') {
    text-align: left;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__header {
    margin-bottom: 48px;
  }

  &__slider {
    margin-left: -16px;
    margin-right: -16px;
    padding-bottom: 20px;

    .splide__pagination {
      bottom: -2px;
      .splide__pagination__page {
        background-color: $color-mirage-300 !important;
        height: 12px !important;
        width: 12px !important;
  
        &.is-active {
          background-color: $color-electric-blue !important;
          height: 12px !important;
          width: 12px !important;
          transform: scale(1) !important;
        }
      }
    }

    .splide__slide{
      padding: 0 24px 36px;
    }
  }

  &__button {
    text-align: center;
    margin-top: 28px;
  }
}