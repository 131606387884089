.dual-section {
  position: relative;
  overflow-x: hidden;
  
  &--padding-normal {
    @extend %PaddingSections;
  }

  &--padding-large {
    @extend %PaddingSections;
    @include media("laptop") {
      padding-bottom: 130px;
      padding-top: 130px;
    }
  }

  &--padding-small {
    padding-bottom: 35px;
    padding-top: 35px;
    @include media("laptop") {
      padding-bottom: 50px;
      padding-top: 50px;
    }
  }

  

  &__background {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    display: block;
    opacity: 0.1;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  &__title {
    margin-bottom: 30px;
    @include media("laptop") {
      margin-bottom: 20px;
    }
    &--with-bar {
      @extend %YellowBarHeading2Before;
    }
  }

  &__block {
    width: 100%;
    @include media("laptop") {
      width: 50%;
      margin-bottom: 1rem;
    }
    &--text {
      align-items: center;
      display: flex;
      margin-bottom: 2rem;
      @include media("tablet-s") {
        margin-bottom: 0;
        margin-right: 48px;
      }
    }
    &--image {
      display: flex;
      flex-direction: row;
      justify-content: center;

      &.ratio-4-3 {
        @include media("tablet-s") {
          justify-content: flex-end;
        }
      }
    }
    &--mosaic {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        display: none;
        width: 81.11px;
        height: 132.5px;
        background-image: url('../../static/pattern-line.svg');
        background-size: cover;
        left: 60%;
  
        @include media('laptop') {
          display: block;
        }
      }

      &::before {
        top: -24px;
        left: 54%;
      }

      &::after {
        left: 20%;
        bottom: -44px;
      }

      picture {
        border-radius: $br-s;
        height: 165px;
        margin-bottom: 20px;
        overflow: hidden;
        position: relative;
        width: calc(33.33% - 10px);
        &:nth-child(3n + 2) {
          margin-bottom: -20px;
          margin-top: 40px;
        }
        img {
          height: 100%;
          left: 0;
          object-fit: cover;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
      &-mobile {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        picture {
          border-radius: $br-s;
          height: 165px;
          margin-bottom: 20px;
          overflow: hidden;
          position: relative;
          width: calc(50% - 10px);
          img {
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: -28px;
    position: relative;
    z-index: 1;
    @include media("tablet-s") {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      &--reversed {
        flex-direction: row-reverse !important;
        .dual-section__block--image.ratio-4-3 {
          justify-content: flex-start !important;
        }

        .dual-section__block--text {
          margin-left: 48px;
          margin-right: 0;
        }
      }
    }
  }

  &__content {
    font-family: $ffamily-general;
    font-size: $fsize-small;

    a {
      &,
      &:link {
        display: inline-flex;
        align-items: center;
        font-size: $fsize-small;
        color: $color-electric-blue;
        text-decoration: none;
        line-height: 18.2px;
        @extend %HeadingFontRegular;

        i {
          font-size: 20px;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    margin-left: -8px;
    margin-right: -8px;
    @include media('phone-l') {
      display: block;
    }

    .btn-primary,
    .btn-secondary {
      margin-top: 16px;
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  &__picture {
    display: flex;
    height: 100%;
    justify-content: center;
    max-height: 340px;
    object-fit: contain;
    box-shadow: 0 14px 28px rgba($color-blue-navy,0.2);
    border-radius: 20px;
  }

  &__image {
    border-radius: 20px;
    object-fit: cover;
    width: 100%;
    max-width: 319px;
    &.ratio-1 {
      aspect-ratio: 1/1;
      max-height: 319px;

      @include media("laptop"){
        max-width: 379.58px;
        max-height: 379.58px;
      }
    }
    &.ratio-4-3 {
      // aspect-ratio: 4/3;
      max-height: 211px;

      @include media("laptop"){
        max-width: 480px;
        max-height: 318px;
      }
    }
  }

  &__double-columns {
    
    @include media("phone-l") {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-column-gap: 20px;
      grid-row-gap: 23px;
    }
  }

  &__column-block {
    margin-bottom: 23px;

    &-title {
      font-size: $fsize-small;
      margin: 0 0 12px;
      line-height: 1.5;
      @extend %GeneralFontBold;
    }

    &-content {
      font-size: $fsize-small;
      margin: 0 0 15px;
      @extend %GeneralFontRegular;
    }

    a {
      &,
      &:link {
        display: inline-flex;
        align-items: center;
        font-size: $fsize-small;
        color: $color-electric-blue;
        text-decoration: none;
        line-height: 18.2px;
        @extend %HeadingFontRegular;

        i {
          font-size: 20px;
        }
      }
    }
  }

  &__column-left,
  &__column-right {
    width: 100%;
    @include media("tablet") {
      width: 45%;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 0;
    }

    strong {
      font-family: $ffamily-general;
      font-weight: $fweight-bold;
    }

    p {
      font-family: $ffamily-general;
      font-weight: $fweight-regular;
      margin-top: 0;
    }

    a {
      align-items: center;
      color: $color-electric-blue;
      display: inline-flex;
      font-family: $ffamily-heading;
      font-size: $fsize-small;
      font-weight: $fweight-regular;
      text-decoration: none;
      position: relative;
      padding-right: 24px;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 24px;
        height: 24px;
        background-image: url('../../static/nav-arrow-right.svg');
        top: 50%;
        transform: translateY(-50%);
      }
      i {
        font-size: 24px;
        text-align: center;
        vertical-align: middle;
      }
    }
  }

  p {
    margin-top: 0;
  }


  &--white {
    background-color: $color-white;
    // h3.dual-section__title {
    //   font-size: $fsize-heading-1-dt !important;
    // } 
  }

  &--lblue {
    background-color: $color-electric-blue-100;
    // h3.dual-section__title {
    //   font-size: $fsize-heading-1-dt !important;
    // }
  }

  &--blue {
    background-color: $color-blue-navy;

    .dual-section__title {
      color: $color-white;
    }

    // h3.dual-section__title {
    //   font-size: $fsize-heading-1-dt !important;
    // }

    .dual-section__content {
      color: $color-white;
    }

    .dual-section__column-left,
    .dual-section__column-right {
      width: 100%;
      @include media("tablet") {
        width: 45%;
      }

      p,
      strong,
      a {
        color: $color-white;
      }
    }
    .dual-section__buttons {
      .btn-secondary {
        border-color: $color-white;
        color: $color-white;
      }
    }

    .dual-section__column-block-content,
    .dual-section__column-block-title {
      color: $color-white;
    }
  }

  // &--blue,
  // &--lblue {
  //   .dual-section__title {
  //     font-size: $fsize-heading-1-dt;
  //   }
  //   .dual-section__content {
  //     font-size: $fsize-general;
  //     @include media("tablet") {
  //       font-size: $fsize-heading-4-dt;
  //     }
  //   }
  // }
}
